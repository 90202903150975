import moment from "moment";

import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { addTimes } from "../../../helpers/util";
import { StyledPdfHeader } from "./timeSheets.styles";

const PdfHeader = ({ project, projectDate, timeLogs }) => {
  return (
    <StyledPdfHeader
      width={1}
      my={40}
      p={[12, 40]}
      mx="auto"
      flexDirection="column"
    >
      <div>
        <Logo />
      </div>
      <h2>{project === null ? "All Projects" : project?.name}</h2>
      <p>
        {projectDate === null
          ? `${`${moment().startOf("month").format("DD MMM")}, ${moment()
              .endOf("month")
              .format("DD MMM YY")}`}`
          : projectDate[0] === null && projectDate[1] === null
          ? "All Dates"
          : `${`${moment(projectDate[0], "YYYY-MM-DD").format(
              "DD MMM"
            )}, ${moment(projectDate[1], "YYYY-MM-DD").format("DD MMM YY")}`}`}
      </p>
      <p>Total hours: {addTimes(timeLogs)}</p>
    </StyledPdfHeader>
  );
};

export default PdfHeader;
