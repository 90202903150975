import React, { useEffect } from "react";

import queryString from "query-string";
import { useHistory } from "react-router-dom";

import SetPassword from "../components/organisms/SetPassword";
import AppLayout from "../Layout";

const SetPasswordPage = () => {
  const history = useHistory();
  const parsed = queryString.parse(window.location.search);

  useEffect(() => {
    if (!parsed.authToken) {
      history.push("/auth");
    }
  }, [history, parsed.authToken]);

  return (
    <AppLayout theme="absolute">
      <SetPassword />
    </AppLayout>
  );
};

export default SetPasswordPage;
