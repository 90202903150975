import React, { useState, useCallback } from "react";

import {
  MessageFilled,
  CheckCircleFilled,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import { Tag, Popconfirm } from "antd";
import { find, assign, isEmpty } from "lodash";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as Yup from "yup";
import "./styles.css";

import { useScroll } from "../../../helpers/useScroll";
import { baseColor } from "../../../settings/foundations";
import {
  setLoggedTimeIdAction,
  setCommentAction,
  resetCommentAction,
} from "../../../stores/actions/comment.action";
import {
  setTimeSheetAction,
  resetTimeSheetAction,
  deleteTimesheetAction,
  setConfirmDeleteAction,
  resetConfirmDeleteAction,
} from "../../../stores/actions/timeSheet.action";
import { Button } from "../../atoms/Button";
import { Flex, Box } from "../../atoms/Flexbox";
import { Title, Paragraph, Text } from "../../atoms/Typography";
import { WrapLongWord } from "../../atoms/WrapLongWord";
import { StyledComments, StyledTableHeadWrapper } from "./timeSheets.styles";

export const useHelper = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [isOpen, setIsOpen] = useState(false);
  const [commentIsOpen, setCommentIsOpen] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const { scrollPosition } = useScroll();

  const [commentUpsertType, setCommentUpsertType] = useState("add");

  const { projects } = useSelector(state => state?.projectReducer);
  const { categories } = useSelector(state => state?.projectCategoryReducer);
  const { commentFormValues } = useSelector(state => state?.commentsReducer);
  const { employees } = useSelector(state => state?.employeeReducer);
  const { user } = useSelector(state => state?.authReducer);
  const { selectedId } = useSelector(state => state?.timeSheetReducer);

  const isAdmin =
    user?.role === "SUPER ADMIN" ||
    user?.role === "ADMIN" ||
    user?.role === "MANAGER";

  const handleCommentCancel = () => {
    setCommentIsOpen(false);
    dispatch(resetCommentAction());
  };

  const handleCommentOpen = useCallback(
    (type, values) => {
      setCommentIsOpen(true);
      setCommentUpsertType(type);

      if (type === "add") {
        dispatch(
          setLoggedTimeIdAction(
            assign(commentFormValues, { LoggedTimeId: values })
          )
        );
      } else {
        dispatch(setCommentAction(values));
      }
    },
    [commentFormValues, dispatch]
  );

  const handleCancel = () => {
    setIsOpen(false);
    dispatch(resetTimeSheetAction());
  };

  const handleOpen = useCallback(
    rowValues => {
      setIsOpen(true);
      localStorage.setItem("scrollPosition", scrollPosition);
      dispatch(setTimeSheetAction(rowValues));
    },
    [dispatch, scrollPosition]
  );

  const showPopconfirm = current => {
    dispatch(setConfirmDeleteAction(current));
    setConfirmVisible(true);
  };

  const handleConfirmOk = () => {
    dispatch(deleteTimesheetAction(selectedId));
    localStorage.setItem("scrollPosition", scrollPosition);
    setConfirmVisible(false);
  };

  const handleConfirmCancel = () => {
    setConfirmVisible(false);
    dispatch(resetConfirmDeleteAction());
  };

  const columns = [
    {
      width: "10%",
      title: "Date",
      className: "table-column-text-align",
      dataIndex: "date",
      key: "date",
      render: date => <Text>{moment(date).format("ddd, DD MMMM")}</Text>,
    },
    {
      width: "20%",
      title: "Description",
      className: "table-column-text-align",
      dataIndex: "description",
      key: "description",
      render: (description, rowValues) => {
        return (
          <>
            <StyledTableHeadWrapper>
              <Flex width={1} alignItems="center" mb={3}>
                <WrapLongWord>{description}</WrapLongWord>
              </Flex>
            </StyledTableHeadWrapper>

            {!isMobile &&
              rowValues.comments.map(values => {
                const computedUser = find(employees, employee => {
                  return employee.id === values.UserId;
                });

                return (
                  <StyledComments
                    maxWidth="300px"
                    bg={baseColor.infoBg}
                    px="16px"
                    py="8px"
                    borderRadius="8px"
                    justifyContent="space-between"
                    mb={2}
                  >
                    <Box width={11 / 12} flexDirection="column">
                      <Title
                        color={baseColor.blue}
                        fontSize={10}
                        fontWeight={500}
                        marginbottom={0}
                      >
                        {moment(values.createdAt).format("DD MMMM hh:mm")},{" "}
                        {`${computedUser?.firstname} ${computedUser?.lastname}`}
                      </Title>
                      <Paragraph
                        color={baseColor.blue}
                        fontSize={12}
                        fontWeight={400}
                        marginbottom={0}
                      >
                        {values.comment}
                      </Paragraph>
                    </Box>
                    <Box width={1 / 12} textAlign="center">
                      {user?.id === computedUser?.id && (
                        <EditFilled
                          style={{ color: baseColor.primary }}
                          onClick={() => handleCommentOpen("edit", values)}
                        />
                      )}
                    </Box>
                  </StyledComments>
                );
              })}
          </>
        );
      },
    },
    {
      width: "10%",
      title: "Project",
      className: "table-column-text-align ",
      responsive: ["xl"],
      key: "project",
      dataIndex: "ProjectId",
      render: ProjectId => {
        const computedProject = find(projects, project => {
          return project.id === ProjectId;
        });
        return (
          <StyledTableHeadWrapper>
            <Tag color="default">{computedProject?.name}</Tag>
          </StyledTableHeadWrapper>
        );
      },
    },
    {
      width: "10%",
      title: "Category",
      className: "table-column-text-align notDisplayOnPrint",
      responsive: ["xl"],
      key: "category",
      dataIndex: "ProjectCategoryId",
      render: ProjectCategoryId => {
        const computedProjectCategory = find(categories, category => {
          return category.id === ProjectCategoryId;
        });
        return <Tag color="default">{computedProjectCategory?.name}</Tag>;
      },
    },
    {
      width: "10%",
      title: "Start Time",
      className: "table-column-text-align ",
      responsive: ["xl"],
      key: "startTime",
      dataIndex: "startTime",
      render: startTime => {
        return (
          <StyledTableHeadWrapper>
            {moment(startTime, "hh:mm:ss").format("HH:mm")}
          </StyledTableHeadWrapper>
        );
      },
    },
    {
      width: "10%",
      title: "End Time",
      className: "table-column-text-align ",
      responsive: ["xl"],
      key: "endTime",
      dataIndex: "endTime",
      render: endTime => {
        return (
          <StyledTableHeadWrapper>
            {moment(endTime, "hh:mm:ss").format("HH:mm")}
          </StyledTableHeadWrapper>
        );
      },
    },
    {
      width: "10%",
      title: "Break Time",
      className: "table-column-text-align ",
      responsive: ["xl"],
      key: "breakTime",
      dataIndex: "breakTime",
      render: breakTime => {
        return (
          <StyledTableHeadWrapper>
            {isEmpty(breakTime)
              ? "00:00"
              : moment(breakTime, "hh:mm:ss").format("HH:mm")}
          </StyledTableHeadWrapper>
        );
      },
    },
    {
      width: "5%",
      title: "Hours",
      className: "table-column-text-align ",
      key: "hours",
      dataIndex: "timeLogged",
      render: endTime => {
        return (
          <StyledTableHeadWrapper>
            {moment(endTime, "hh:mm:ss").format("HH:mm")}
          </StyledTableHeadWrapper>
        );
      },
    },
    {
      width: "5%",
      title: "Status",
      className: "table-column-text-align notDisplayOnPrint",
      responsive: ["xl"],

      key: "status",
      dataIndex: "status",
      render: value => {
        return (
          <Flex width={1} justifyContent="center" p="5px">
            <CheckCircleFilled
              style={{ color: value ? baseColor.green : baseColor.midGray }}
            />
          </Flex>
        );
      },
    },
    {
      width: "15%",
      title: "Action",
      key: "action",
      className:
        "table-column-text-align table-action-column notDisplayOnPrint",
      render: (value, rowValues) => (
        <Flex width={1} flexDirection={["column", "row"]}>
          <Box mr={[1, 2]} mb={[1, 0]}>
            <Button
              disabled={!isAdmin && rowValues?.status}
              onClick={() => handleOpen(rowValues)}
              icon={
                <EditFilled
                  style={{
                    color:
                      !isAdmin && rowValues?.status
                        ? baseColor.gray
                        : baseColor.primary,
                    opacity: !isAdmin && rowValues?.status ? 0.45 : 1,
                  }}
                />
              }
            />
          </Box>
          {isAdmin && (
            <Box mr={[1, 2]} mb={[1, 0]}>
              <Button
                onClick={() => handleCommentOpen("add", rowValues?.id)}
                icon={<MessageFilled style={{ color: baseColor.primary }} />}
              />
            </Box>
          )}
          {isAdmin && (
            <Box>
              <Popconfirm
                placement="topRight"
                title="Are you sure to delete this time log?"
                visible={selectedId === rowValues?.id && confirmVisible}
                onConfirm={handleConfirmOk}
                onCancel={handleConfirmCancel}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  onClick={() => showPopconfirm(rowValues?.id)}
                  icon={<DeleteFilled style={{ color: baseColor.darkRed }} />}
                />
              </Popconfirm>
            </Box>
          )}
        </Flex>
      ),
    },
  ];

  const TimeTrackingSchema = Yup.object().shape({
    date: Yup.string().required("Date is a required field!").nullable(),
    startTime: Yup.string()
      .required("Start time is a required field!")
      .nullable(),
    endTime: Yup.string()
      .test("is-greater", "end time should be greater", function (value) {
        const { startTime } = this.parent;
        return moment(value, "HH:mm a").isAfter(moment(startTime, "HH:mm a"));
      })
      .required("End time is a required field!")
      .nullable(),
    breakTime: Yup.string()
      .required("Break time is a required field!")
      .nullable(),
    ProjectId: Yup.string().required("Project is a required field!"),
    ProjectCategoryId: Yup.string().required(
      "Project category is a required field!"
    ),
    description: Yup.string().required("Description is a required field!"),
  });

  return {
    isOpen,
    columns,
    commentIsOpen,
    handleCommentCancel,
    handleCancel,
    TimeTrackingSchema,
    commentUpsertType,
  };
};
