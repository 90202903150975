import styled from "@emotion/styled";
import { Link as RouterLink } from "react-router-dom";

export const Link = styled(RouterLink)`
  font-family: "Roboto";
  font-size: ${props =>
    props.fontSize ? `${props.fontSize}px !important` : ""};
  font-weight: ${props =>
    props.fontWeight ? `${props.fontWeight}px !important` : ""};
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px !important` : ""};
  color: ${props => (props.color ? `${props.color} !important` : "")};
`;
