import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { ReactComponent as TimerIcon } from "../../../assets/icons/Timer.svg";
import { baseColor } from "../../../settings/foundations";
import { getProjectsAction } from "../../../stores/actions/project.actions";
import { getProjectCategoryAction } from "../../../stores/actions/projectCategory.action";
import { Flex, Box } from "../../atoms/Flexbox";
import { Title, Text } from "../../atoms/Typography";
import Form from "./form";

const TimeTrackingPanel = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useDispatch();

  const { user } = useSelector(state => state?.authReducer);

  useEffect(() => {
    dispatch(getProjectsAction());
    dispatch(getProjectCategoryAction());
  }, [dispatch]);

  return (
    <Flex
      bg={baseColor.white}
      width={1}
      mb={120}
      flexDirection="column"
      mx="auto"
      height="100%"
    >
      <Flex width={1} flexDirection={["column", "row"]}>
        <Box width={[1, 1 / 2]} mt={[10, 10, 50]} pl={10}>
          <Title
            fontSize={isTabletOrMobile ? 22 : 38}
            color={baseColor.gray}
            marginbottom={isTabletOrMobile ? 4 : 16}
          >
            Hi {`${user?.firstname} ${user?.lastname}`}!
          </Title>
          <Text
            fontSize={isTabletOrMobile ? 14 : 16}
            color={baseColor.gray}
            marginbottom={40}
          >
            Track your time
          </Text>
          <Box textAlign="center" mt={isTabletOrMobile ? 2 : 4}>
            <TimerIcon
              width={isTabletOrMobile ? "160" : "250"}
              height={isTabletOrMobile ? "160" : "250"}
            />
          </Box>
        </Box>
        <Box width={[1, 1 / 2]} mt={[0, 0, 50]}>
          <Box maxWidth={[300, 350, 370]} margin="auto" mt={[3, 4, 50]}>
            <Form />
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default TimeTrackingPanel;
