import { message } from "antd";

import { employeeConstants } from "../../constants";
import http from "../../services/httpService";

export const getEmployeesAction = () => {
  return dispatch => {
    dispatch({ type: employeeConstants.GET_EMPLOYEE_REQUEST });
    http
      .get("/users/all")
      .then(res => {
        dispatch({
          type: employeeConstants.GET_EMPLOYEE_SUCCESS,
          payload: res.data.users,
        });
      })
      .catch(() => {
        message.error("Unable to get employees data");
        dispatch({ type: employeeConstants.GET_EMPLOYEE_FAILURE });
      });
  };
};

export function addEmployeeAction(payload) {
  return dispatch => {
    dispatch({ type: employeeConstants.ADD_EMPLOYEE_REQUEST });
    http
      .post("/users/register", payload)
      .then(res => {
        message.success("Employee added successfully!");
        dispatch({
          type: employeeConstants.ADD_EMPLOYEE_SUCCESS,
          payload: res.data.user,
        });
      })
      .catch(() => {
        message.error("Something went wrong. unable to add employee.");
        dispatch({
          type: employeeConstants.ADD_EMPLOYEE_FAILURE,
        });
      });
  };
}

export function updateEmployeeAction(uid, payload) {
  return dispatch => {
    dispatch({ type: employeeConstants.UPDATE_EMPLOYEE_REQUEST });
    http
      .patch(`/users/${uid}`, payload)
      .then(res => {
        message.success("Employee data updated successfully!");
        dispatch({
          type: employeeConstants.UPDATE_EMPLOYEE_SUCCESS,
          id: uid,
          payload: res.data.user,
        });
      })
      .catch(() => {
        message.error("Something went wrong. unable to update employee data");
        dispatch({
          type: employeeConstants.UPDATE_EMPLOYEE_FAILURE,
        });
      });
  };
}

export function setEmployeeAction(data) {
  return dispatch => {
    dispatch({ type: employeeConstants.SET_EMPLOYEE_DATA, payload: data });
  };
}

export function resetEmployeeAction() {
  return dispatch => {
    dispatch({ type: employeeConstants.RESET_EMPLOYEE_DATA });
  };
}
