/* eslint-disable react/jsx-boolean-value */
import React, { useEffect } from "react";

import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Formik, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { baseColor } from "../../../../settings/foundations";
import {
  addProjectCategoryAction,
  updateProjectCategoryAction,
} from "../../../../stores/actions/projectCategory.action";
import { Button } from "../../../atoms/Button";
import { Flex, Box } from "../../../atoms/Flexbox";
import { Input } from "../../../atoms/Input";
import { Select, Option } from "../../../atoms/Select";
import { Text } from "../../../atoms/Typography";
import { useHelper } from "./useHelper";

const CategoriesForm = ({ handleCancel, upsertType }) => {
  const dispatch = useDispatch();
  const helper = useHelper();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });

  const { types } = useSelector(state => state?.projectTypeReducer);
  const { isLoading } = useSelector(state => state?.projectCategoryReducer);
  const { category } = useSelector(state => state?.projectCategoryReducer);

  useEffect(() => {
    if (isLoading) {
      handleCancel();
    }
  }, [handleCancel, isLoading]);

  return (
    <Formik
      validationSchema={helper.categoriesSchema}
      initialValues={category}
      onSubmit={values => {
        if (upsertType === "add") {
          dispatch(addProjectCategoryAction(values));
        } else if (upsertType === "edit") {
          dispatch(updateProjectCategoryAction(category.id, values));
        }
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Flex flexDirection="column" width={1} p={["8px", 16]}>
            <Input
              size="large"
              name="name"
              type="text"
              placeholder="Category Name"
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              value={props.values.name}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="name" />
            </Text>
          </Flex>
          <Flex width={1} flexDirection={["column", "row"]}>
            <Box width={[1, 1 / 2]} p={["8px", 16]}>
              <Select
                size="large"
                placeholder="Status"
                value={props.values.status}
                onChange={value => {
                  props.setFieldValue("status", value);
                }}
                onBlur={value => props.setFieldTouched("status", value)}
              >
                <Option value={true}>Active</Option>
                <Option value={false}>Disable</Option>
              </Select>
              <Text color={baseColor.red}>
                <ErrorMessage name="status" />
              </Text>
            </Box>
            <Box width={[1, 1 / 2]} p={["8px", 16]}>
              <Select
                size="large"
                placeholder="Type"
                value={props.values.type || 0}
                onChange={value => {
                  props.setFieldValue("type", value);
                }}
                onBlur={value => props.setFieldTouched("type", value)}
              >
                <Option value={0} disabled>
                  Select Type
                </Option>
                {types.map(type => (
                  <Option key={type.id} value={type.name}>
                    {type.name}
                  </Option>
                ))}
              </Select>
              <Text color={baseColor.red}>
                <ErrorMessage name="type" />
              </Text>
            </Box>
          </Flex>
          <Flex
            width={1}
            p={16}
            position={isTabletOrMobile ? "absolute" : "static"}
            bottom="110px"
            left="-10px"
            justifyContent="flex-end"
          >
            <Button
              size="large"
              type="default"
              icon={<CloseOutlined />}
              marginright={8}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              size="large"
              htmlType="submit"
              type="primary"
              loading={isLoading}
              icon={<CheckOutlined />}
            >
              Save
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default CategoriesForm;
