import React, { useEffect, useState } from "react";

import { CloudDownloadOutlined } from "@ant-design/icons";
import { filter } from "lodash";
// import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import XLSX from "xlsx";

import { addTimes } from "../../../helpers/util";
import { baseColor } from "../../../settings/foundations";
import { getProjectsAction } from "../../../stores/actions/project.actions";
import { getEmployeeSalaryAction } from "../../../stores/actions/salary.action";
import { Button } from "../../atoms/Button";
import { Flex } from "../../atoms/Flexbox";
import { Title, Text } from "../../atoms/Typography";
import Filter from "./filter";
import SalariesTable from "./table";

const Salaries = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
  const dispatch = useDispatch();

  const [CSVdata, setCSV] = useState([]);

  const { timeLogs } = useSelector(state => state?.salaryReducer);
  const { submitting } = useSelector(state => state?.timeTrackingReducer);

  // const headers = [
  //   { label: "First Name", key: "firstname" },
  //   { label: "Last Name", key: "lastname" },
  //   { label: "TOTAL HOURS", key: "totalhours" },
  // ];

  const handleSelectedRows = (selectedRowKeys, employees) => {
    let temp = null;
    const data = [];
    for (let i = 0; i < selectedRowKeys.length; i++) {
      temp = filter(employees, value => value.id === selectedRowKeys[i]);
      if (temp.length > 0) {
        const obj = {
          firstname: temp[0].firstname,
          lastname: temp[0].lastname,
          totalhours: addTimes(temp[0].loggedTimes),
        };
        data.push(obj);
      }
    }
    setCSV(data);
  };

  const handleSubmit = (selectedProject, startDate, endDate) => {
    dispatch(getEmployeeSalaryAction(selectedProject, startDate, endDate));
  };

  const downloadxls = (e, data) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Umax Employee Salaries");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "umax-emloyee-salaries.xlsx");
  };

  useEffect(() => {
    dispatch(getProjectsAction());
    dispatch(getEmployeeSalaryAction());
  }, [dispatch, submitting]);

  return (
    <Flex
      bg={baseColor.white}
      maxWidth={["100%", "100%", "1220px", "1440px"]}
      px={[0, "20px"]}
      flexDirection="column"
      mx="auto"
      mb={90}
    >
      <Filter onHandleSubmit={handleSubmit} />
      <Flex
        justifyContent="space-between"
        mt={32}
        px={[12, 10, 0]}
        alignItems={["center", "initial"]}
      >
        <Title fontSize={24} color={baseColor.black} marginbottom={0}>
          All projects
        </Title>

        {/* <div
          onClick={() => {
            if (CSVdata.length > 0) return true;
            return false;
          }}
          // data={CSVdata}
          // headers={headers}
          // filename="umax-emloyee-salaries.xlsx"
        > */}
        <Button
          onClick={e => {
            if (CSVdata.length > 0) downloadxls(e, CSVdata);
            return false;
          }}
          paddingtop={isTabletOrMobile ? 10 : 0}
          paddingbottom={isTabletOrMobile ? 10 : 0}
          type="primary"
          fontSize={14}
          icon={<CloudDownloadOutlined />}
        >
          Export selected as Excel File
        </Button>
        {/* </div> */}
      </Flex>
      <Flex px={[12, 10, 0]}>
        <Text fontSize={16} color={baseColor.darkGray}>
          Total hours: {addTimes(timeLogs)}
        </Text>
      </Flex>
      <SalariesTable handleSelectedRows={handleSelectedRows} />
    </Flex>
  );
};

export default Salaries;
