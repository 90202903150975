import styled from "@emotion/styled";
import { DatePicker as AntDatePicker, ConfigProvider } from "antd";

import "moment/locale/en-gb";
import locale from "antd/es/locale/en_GB";
import moment from "moment";

import { baseColor } from "../../../settings/foundations";

const CustomDatePicker = props => {
  moment.locale("en-gb");

  return (
    <ConfigProvider locale={locale}>
      <AntDatePicker {...props} />
    </ConfigProvider>
  );
};

export const DatePicker = styled(CustomDatePicker)`
  font-family: "Roboto";
  width: ${props => (props.width ? `${props.width} !important` : "")};
  font-size: ${props =>
    props.fontSize ? `${props.fontSize}px !important` : ""};
  font-weight: ${props =>
    props.fontWeight ? `${props.fontWeight} !important` : "400"};
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px !important` : ""};
  color: ${props =>
    props.color ? `${props.color} !important` : baseColor.lightBlack};
  padding: ${props => (props.padding ? `${props.padding}px !important` : "")};
  padding-bottom: ${props =>
    props.paddingbottom ? `${props.paddingbottom}px !important` : ""};
  padding-top: ${props =>
    props.paddingtop ? `${props.paddingtop}px !important` : ""};
  padding-left: ${props =>
    props.paddingleft ? `${props.paddingleft}px !important` : ""};
  padding-right: ${props =>
    props.paddingright ? `${props.paddingright}px !important` : ""};
  margin: ${props => (props.margin ? `${props.margin}px !important` : "")};
  margin-bottom: ${props =>
    props.marginbottom ? `${props.marginbottom}px !important` : ""};
  margin-top: ${props =>
    props.margintop ? `${props.margintop}px !important` : ""};
  margin-left: ${props =>
    props.marginleft ? `${props.marginleft}px !important` : ""};
  margin-right: ${props =>
    props.marginright ? `${props.marginright}px !important` : ""};

  .ant-picker-suffix {
    color: ${baseColor.black};
  }
`;
