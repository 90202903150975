import { timeTrackingConstants } from "../../constants";

export default function timeTrackingReducer(
  state = { submitting: false },
  action
) {
  switch (action.type) {
    case timeTrackingConstants.ADD_TIME_REQUEST:
      return {
        submitting: true,
      };
    case timeTrackingConstants.ADD_TIME_SUCCESS:
      return {
        submitting: false,
      };
    case timeTrackingConstants.ADD_TIME_FAILURE:
      return {
        submitting: false,
      };
    default:
      return state;
  }
}
