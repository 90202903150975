import React from "react";

import { CheckCircleFilled } from "@ant-design/icons";
import { Table } from "antd";
import { filter, forEach } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { useScroll } from "../../../helpers/useScroll";
import { addTimes } from "../../../helpers/util";
import { baseColor } from "../../../settings/foundations";
import { approveEmployeeLoggedTime } from "../../../stores/actions/timeSheet.action";
import { Button } from "../../atoms/Button";
import { Flex } from "../../atoms/Flexbox";
import { Title } from "../../atoms/Typography";
import CommentUpsert from "./commentUpsert";
import { StyledActionButtons, StyledTableContent } from "./timeSheets.styles";
import Upsert from "./upsert";
import { useHelper } from "./useHelper";

const TimeSheetTable = ({ weekTimeLogs, isAdmin }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { scrollPosition } = useScroll();

  const unApprovedTimeId = [];
  const helper = useHelper();
  const dispatch = useDispatch();

  const { isLoading } = useSelector(state => state?.timeSheetReducer);

  const unApprovedTimeLogs = filter(weekTimeLogs?.timeLogs, timeLog => {
    return timeLog?.status === false;
  });

  forEach(unApprovedTimeLogs, obj => {
    unApprovedTimeId.push(obj.id);
  });

  weekTimeLogs.timeLogs.sort(
    (a, b) =>
      moment(a.date).format("YYYYMMDD") - moment(b.date).format("YYYYMMDD")
  );

  return (
    <>
      <Flex width={1} flexDirection="column">
        <Flex
          width={1}
          mt={50}
          justifyContent="space-between"
          alignItems="center"
        >
          <Title fontSize={16} color={baseColor.lightBlack}>
            Week {weekTimeLogs?.week}
          </Title>
          <StyledActionButtons>
            <Flex>
              {unApprovedTimeLogs.length !== 0 && isAdmin && (
                <Button
                  paddingtop={isMobile ? 9 : 0}
                  paddingbottom={isMobile ? 9 : 0}
                  type="primary"
                  fontSize={isMobile ? 12 : 14}
                  loading={isLoading}
                  backgroundColor={baseColor.green}
                  borderColor={baseColor.green}
                  icon={<CheckCircleFilled />}
                  onClick={() => {
                    dispatch(approveEmployeeLoggedTime(unApprovedTimeId));
                    localStorage.setItem("scrollPosition", scrollPosition);
                  }}
                >
                  Approve {addTimes(unApprovedTimeLogs)} hours
                </Button>
              )}
            </Flex>
          </StyledActionButtons>
        </Flex>
        <Flex width={1} flexDirection="column" mt={14}>
          <StyledTableContent>
            <Table
              rowKey="id"
              pagination={false}
              columns={helper.columns}
              dataSource={weekTimeLogs?.timeLogs}
            />
          </StyledTableContent>
        </Flex>
      </Flex>
      <Upsert isOpen={helper.isOpen} handleCancel={helper.handleCancel} />
      <CommentUpsert
        upsertType={helper.commentUpsertType}
        isOpen={helper.commentIsOpen}
        handleCancel={helper.handleCommentCancel}
      />
    </>
  );
};

export default TimeSheetTable;
