import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import TimeTracking from "../components/organisms/TimeTracking";
import AppLayout from "../Layout";
import { checkAuth } from "../stores/actions/auth.actions";

const TimeTrakingPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  return (
    <AppLayout theme="primary" isprimary>
      <TimeTracking />
    </AppLayout>
  );
};

export default TimeTrakingPage;
