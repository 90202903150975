import React from "react";

import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Formik, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { baseColor } from "../../../settings/foundations";
import { login } from "../../../stores/actions/auth.actions";
import { Button } from "../../atoms/Button";
import { Box } from "../../atoms/Flexbox";
import { Input, Password } from "../../atoms/Input";
import { Text } from "../../atoms/Typography";

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address!")
    .required("Email is a required field!"),
  password: Yup.string().required("Password is a required field!"),
});

const Form = () => {
  const dispatch = useDispatch();
  const loggingIn = useSelector(state => state?.authReducer?.loggingIn);

  return (
    <Formik
      validationSchema={SigninSchema}
      initialValues={{ email: "", password: "" }}
      onSubmit={values => {
        dispatch(login(values.email, values.password));
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Box display="flex" flexDirection="column" marginBottom={16}>
            <Input
              size="large"
              name="email"
              type="text"
              placeholder="Email"
              onBlur={props.handleBlur}
              onChange={e => {
                const value = e.target.value || "";
                props.setFieldValue("email", value.toLowerCase());
              }}
              value={props.values.email}
              prefix={<UserOutlined style={{ color: baseColor.primary }} />}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="email" />
            </Text>
          </Box>
          <Box display="flex" flexDirection="column" marginBottom={24}>
            <Password
              size="large"
              name="password"
              type="password"
              placeholder="Password"
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              value={props.values.password}
              prefix={<LockOutlined style={{ color: baseColor.primary }} />}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="password" />
            </Text>
          </Box>

          <Button
            block
            type="primary"
            fontSize={16}
            size="large"
            htmlType="submit"
            loading={loggingIn}
          >
            Sign In
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default Form;
