import styled from "@emotion/styled";
import { Link } from "react-scroll";

export const StyledList = styled.ul`
  position: fixed;
  width: 120px;
  margin: 16px 0;
  padding-left: 0;
  list-style: none;
  border-right: 1px solid #f0f0f0;

  li {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`;

export const StyledItem = styled(Link)`
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000;

  &.active {
    color: #1890ff;

    &::after {
      content: "";
      position: absolute;
      right: -1.25px;
      border-right: 2px solid #1890ff;
      height: 30px;
    }
  }
`;
