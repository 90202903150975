import React from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Table, Tabs } from "antd";
import { sortBy } from "lodash";
import { useSelector } from "react-redux";

import { baseColor } from "../../../../settings/foundations";
import { Button } from "../../../atoms/Button";
import { Flex } from "../../../atoms/Flexbox";
import { Title } from "../../../atoms/Typography";
import Upsert from "./upsert";
import { useHelper } from "./useHelper";

const Employees = () => {
  const helper = useHelper();
  const { TabPane } = Tabs;

  const { employees, isLoading } = useSelector(state => state?.employeeReducer);
  function ActiveTable() {
    const activeData = sortBy(
      employees.filter(item => item.status),
      "firstname"
    );
    return (
      <Table
        rowKey="id"
        pagination={false}
        loading={isLoading}
        columns={helper.columns}
        dataSource={activeData}
      />
    );
  }
  function DisabledTable() {
    const disabledData = sortBy(
      employees.filter(item => !item.status),
      "firstname"
    );
    return (
      <Table
        rowKey="id"
        pagination={false}
        loading={isLoading}
        columns={helper.columns}
        dataSource={disabledData}
      />
    );
  }
  return (
    <>
      <Flex flexDirection="column" width={1} mt={80} id="people">
        <Flex alignItems="baseline" justifyContent="space-between" mb={16}>
          <Title fontSize={24} color={baseColor.gray}>
            Employees
          </Title>

          <Button
            paddingtop={8}
            paddingbottom={8}
            type="primary"
            fontSize={14}
            icon={<PlusOutlined />}
            onClick={() => helper.handleOpen("add")}
          >
            Add new
          </Button>
        </Flex>

        <Flex width={1} flexDirection="column">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Active" key="1">
              <ActiveTable />
            </TabPane>
            <TabPane tab="Archived" key="2">
              <DisabledTable />
            </TabPane>
          </Tabs>
        </Flex>
      </Flex>
      <Upsert
        isOpen={helper.isOpen}
        handleCancel={helper.handleCancel}
        upsertType={helper.upsertType}
      />
    </>
  );
};

export default Employees;
