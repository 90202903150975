import React from "react";

import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import NotFoundPage from "../pages/404";
import AuthPage from "../pages/auth";
import ForgotPasswordPage from "../pages/forgotPassword";
import SalariesPage from "../pages/salaries";
import SetPasswordPage from "../pages/setPassword";
import SettingsPage from "../pages/settings";
import StatisticsPage from "../pages/statistics";
import TimeSheetsPage from "../pages/timeSheets";
import TimeTrakingPage from "../pages/timeTracking";
import PrivateRoute from "./privateRoute";

const AppRoutes = () => {
  const { user } = useSelector(state => state?.authReducer);

  if (!isEqual(window.location.pathname, "/time-sheets")) {
    sessionStorage.removeItem("filterValues");
    localStorage.removeItem("scrollPosition");
  }

  return (
    <Router>
      <Switch>
        <Route path="/auth" component={AuthPage} exact />
        <Route path="/forgot-password" component={ForgotPasswordPage} exact />
        <PrivateRoute
          path="/settings"
          component={SettingsPage}
          show={user?.role === "SUPER ADMIN" || user?.role === "ADMIN"}
          exact
        />
        <PrivateRoute
          path="/salaries"
          component={SalariesPage}
          show={
            user?.role === "SUPER ADMIN" ||
            user?.role === "ADMIN" ||
            user?.role === "MANAGER"
          }
          exact
        />
        <PrivateRoute
          path="/time-sheets"
          component={TimeSheetsPage}
          show={
            user?.role === "SUPER ADMIN" ||
            user?.role === "ADMIN" ||
            user?.role === "MANAGER" ||
            user?.role === "COLLABORATOR"
          }
          exact
        />
        <PrivateRoute
          path="/statistics"
          component={StatisticsPage}
          show={
            user?.role === "SUPER ADMIN" ||
            user?.role === "ADMIN" ||
            user?.role === "MANAGER"
          }
          exact
        />
        <PrivateRoute
          path="/time-tracking"
          component={TimeTrakingPage}
          show={
            user?.role === "SUPER ADMIN" ||
            user?.role === "ADMIN" ||
            user?.role === "MANAGER" ||
            user?.role === "COLLABORATOR"
          }
          exact
        />
        <Route path="/set-password" component={SetPasswordPage} exact />
        <Redirect from="/" to="/auth" exact />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
