import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import { getEmployeesAction } from "../../../stores/actions/employee.action";
import { getProjectsAction } from "../../../stores/actions/project.actions";
import { getProjectCategoryAction } from "../../../stores/actions/projectCategory.action";
import { getProjectTypeAction } from "../../../stores/actions/projectType.action";
import { getRolesAction } from "../../../stores/actions/role.action";
import { Flex, Box } from "../../atoms/Flexbox";
import SideScroll from "../../molecules/SideScroll";
import Categories from "./categories";
import Employees from "./employees";
import Projects from "./projects";
import Types from "./types";

const tabs = [
  {
    key: "1",
    title: "Projects",
    path: "projects",
    active: "active",
  },
  {
    key: "2",
    title: "People",
    path: "people",
  },
  {
    key: "3",
    title: "Categories",
    path: "categories",
  },
  {
    key: "4",
    title: "Types",
    path: "types",
  },
];

const Settings = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("projects");

  const handleScroll = tab => {
    setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(getProjectsAction());
    dispatch(getEmployeesAction());
    dispatch(getProjectCategoryAction());
    dispatch(getProjectTypeAction());
    dispatch(getRolesAction());
  }, [dispatch]);

  return (
    <Flex
      maxWidth={["100%", "100%", "1220px", "1440px"]}
      px={[0, "20px"]}
      mx="auto"
      mt={40}
      mb="25%"
    >
      <Box display={["none", "none", "block"]} width={1 / 10}>
        <SideScroll
          menu={tabs}
          activeTab={activeTab}
          handleScroll={handleScroll}
        />
      </Box>
      <Box
        width={[1, 9 / 10]}
        flexDirection="column"
        ml={[0, 30]}
        px={[12, 10, 0]}
      >
        <Projects />
        <Employees />
        <Categories />
        <Types />
      </Box>
    </Flex>
  );
};

export default Settings;
