/* eslint-disable react/jsx-boolean-value */
import React, { useEffect } from "react";

import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Formik, ErrorMessage } from "formik";
import { isEmpty } from "lodash";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { baseColor } from "../../../../settings/foundations";
import {
  addEmployeeAction,
  updateEmployeeAction,
} from "../../../../stores/actions/employee.action";
import { Button } from "../../../atoms/Button";
import { DatePicker } from "../../../atoms/DatePicker";
import { Flex, Box } from "../../../atoms/Flexbox";
import { Input } from "../../../atoms/Input";
import { Select, Option } from "../../../atoms/Select";
import { Text } from "../../../atoms/Typography";
import { useHelper } from "./useHelper";

const EmployeesForm = ({ handleCancel, upsertType }) => {
  const helper = useHelper();
  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });

  const { roles } = useSelector(state => state?.rolesReducer);
  const { isLoading } = useSelector(state => state?.employeeReducer);
  const { employee } = useSelector(state => state?.employeeReducer);

  useEffect(() => {
    if (isLoading) {
      handleCancel();
    }
  }, [handleCancel, isLoading]);

  return (
    <Formik
      validationSchema={helper.employeesSchema}
      initialValues={employee}
      onSubmit={values => {
        if (upsertType === "add") {
          dispatch(addEmployeeAction(values));
        } else if (upsertType === "edit") {
          dispatch(updateEmployeeAction(employee.id, values));
        }
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Flex width={1} flexDirection={["column", "row"]}>
            <Box width={[1, 1 / 2]} p={["8px", 16]}>
              <Input
                size="large"
                name="firstname"
                type="text"
                placeholder="First Name"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.firstname}
              />
              <Text color={baseColor.red}>
                <ErrorMessage name="firstname" />
              </Text>
            </Box>
            <Box width={[1, 1 / 2]} p={["8px", 16]}>
              <Input
                size="large"
                name="lastname"
                type="text"
                placeholder="Last Name"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.lastname}
              />
            </Box>
          </Flex>
          <Flex width={1} flexDirection={["column", "row"]}>
            <Box width={[1, 1 / 2]} p={["8px", 16]}>
              <Input
                size="large"
                name="email"
                type="text"
                placeholder="Email"
                onBlur={props.handleBlur}
                onChange={e => {
                  const value = e.target.value || "";
                  props.setFieldValue("email", value.toLowerCase());
                }}
                value={props.values.email}
              />
              <Text color={baseColor.red}>
                <ErrorMessage name="email" />
              </Text>
            </Box>
            <Box width={[1, 1 / 2]} p={["8px", 16]}>
              <DatePicker
                size="large"
                inputReadOnly
                name="dob"
                width="100%"
                placeholder="DD.MM.YYYY"
                format="DD.MM.YYYY"
                value={
                  isEmpty(props.values.dob) ? null : moment(props.values.dob)
                }
                onChange={value => {
                  props.setFieldValue("dob", value);
                }}
              />
              <Text color={baseColor.red}>
                <ErrorMessage name="dob" />
              </Text>
            </Box>
          </Flex>
          <Flex width={1} flexDirection={["column", "row"]}>
            <Box width={[1, 1 / 2]} p={["8px", 16]}>
              <Select
                size="large"
                placeholder="Status"
                value={props.values.status}
                onChange={value => {
                  props.setFieldValue("status", value);
                }}
                onBlur={value => props.setFieldTouched("status", value)}
              >
                <Option value={true}>Active</Option>
                <Option value={false}>Disable</Option>
              </Select>
              <Text color={baseColor.red}>
                <ErrorMessage name="status" />
              </Text>
            </Box>

            <Box width={[1, 1 / 2]} p={["8px", 16]}>
              <Select
                size="large"
                placeholder="Role"
                value={props.values.role || 0}
                onChange={value => {
                  props.setFieldValue("role", value);
                }}
                onBlur={value => props.setFieldTouched("role", value)}
              >
                <Option value={0} disabled>
                  Select Role
                </Option>
                {roles.map(role => (
                  <Option key={role} value={role}>
                    {role}
                  </Option>
                ))}
              </Select>
              <Text color={baseColor.red}>
                <ErrorMessage name="role" />
              </Text>
            </Box>
          </Flex>
          <Flex
            width={1}
            p={16}
            position={isTabletOrMobile ? "absolute" : "static"}
            bottom="110px"
            left="-10px"
            justifyContent="flex-end"
          >
            <Button
              size="large"
              type="default"
              marginright={8}
              onClick={handleCancel}
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>
            <Button
              size="large"
              htmlType="submit"
              type="primary"
              loading={isLoading}
              icon={<CheckOutlined />}
            >
              Save
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default EmployeesForm;
