import styled from "@emotion/styled";
import { Layout as AntLayout } from "antd";

import { baseColor } from "../settings/foundations";

export const Content = styled(AntLayout.Content)`
  background-color: ${baseColor.white};
  margin-top: ${props => (props.isprimary ? "80px" : "")};
`;

export const Layout = styled(AntLayout)`
  background-color: ${baseColor.white};
`;
