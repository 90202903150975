import React from "react";

import { ProjectOutlined } from "@ant-design/icons";
import { Formik } from "formik";
import { isEmpty, sortBy } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";

import { baseColor } from "../../../settings/foundations";
import { Button } from "../../atoms/Button";
import { Flex, Box } from "../../atoms/Flexbox";
import { RangeDatePicker } from "../../atoms/RangePicker";
import { Select, Option } from "../../atoms/Select";

const Filter = ({ onHandleSubmit }) => {
  const { projects } = useSelector(state => state?.projectReducer);

  const filteredProjects = sortBy(
    projects.filter(item => item.status),
    "name"
  );
  return (
    <Formik
      initialValues={{
        projectId: null,
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
      }}
      onSubmit={values => {
        onHandleSubmit(values.projectId, values.startDate, values.endDate);
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Flex
            width={1}
            flexDirection={["column", "row"]}
            my={24}
            px={[12, 10, 0]}
          >
            <Box width={[1, 2 / 12]} mr={[0, 24]} mb={[15, 0]}>
              <Select
                size="large"
                split
                allowClear
                showSearch
                onClear={() => {
                  props.setFieldValue("projectId", null);
                }}
                value={props.values.projectId || 0}
                onChange={value => {
                  props.setFieldValue("projectId", value);
                }}
                suffixIcon={
                  <ProjectOutlined style={{ color: baseColor.lightBlack }} />
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={0} disabled>
                  Choose Project
                </Option>
                {filteredProjects.map(project => (
                  <Option key={project.id} value={project.id}>
                    {project.name}
                  </Option>
                ))}
              </Select>
            </Box>

            <Box width={[1, 2 / 12]} mr={[0, 24]} mb={[15, 0]}>
              <RangeDatePicker
                size="large"
                width="100%"
                inputReadOnly
                defaultValue={[
                  moment().startOf("month"),
                  moment().endOf("month"),
                ]}
                onClear={() => {
                  props.setFieldValue("startDate", null);
                  props.setFieldValue("endDate", null);
                }}
                onChange={date => {
                  props.setFieldValue(
                    "startDate",
                    isEmpty(date) ? null : moment(date[0]).format("YYYY-MM-DD")
                  );
                  props.setFieldValue(
                    "endDate",
                    isEmpty(date) ? null : moment(date[1]).format("YYYY-MM-DD")
                  );
                }}
              />
            </Box>

            <Box
              width={[1, "116px"]}
              display="flex"
              justifyContent={["initial", "flex-end"]}
            >
              <Button block type="primary" size="large" htmlType="submit">
                Apply filters
              </Button>
            </Box>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default Filter;
