import { userConstants } from "../../constants";

const token = localStorage.getItem("token");
const user = JSON.parse(localStorage.getItem("user"));
const initialState = token && user ? { loggedIn: true, token, user } : {};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case userConstants.SET_USERS_DETAILS:
      return {
        ...state,
        user: action.payload,
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
