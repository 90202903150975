import { useCallback } from "react";

import {
  ProjectOutlined,
  UserOutlined,
  GroupOutlined,
} from "@ant-design/icons";
import { Formik } from "formik";
import { isEmpty, isUndefined, isNull, sortBy } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";

import { baseColor } from "../../../settings/foundations";
import { Button } from "../../atoms/Button";
import { Flex, Box } from "../../atoms/Flexbox";
import { RangeDatePicker } from "../../atoms/RangePicker";
import { Select, Option } from "../../atoms/Select";

const Filter = ({ onHandleSubmit, isAdmin, handlePrint, setProjectDate }) => {
  const { projects } = useSelector(state => state?.projectReducer);
  const { categories } = useSelector(state => state?.projectCategoryReducer);
  const { employees } = useSelector(state => state?.employeeReducer);
  const { user } = useSelector(state => state?.authReducer);

  const filteredProjects = sortBy(
    projects.filter(item => item.status),
    "name"
  );
  const filteredCategories = sortBy(
    categories.filter(item => item.status),
    "name"
  );
  const filteredEmployees = sortBy(
    employees.filter(item => item.status),
    "firstname"
  );

  const isCollaborator = user?.role === "COLLABORATOR";

  const filterItems = JSON.parse(sessionStorage.getItem("filterValues"));
  const projectCategoryID = sessionStorage.getItem("projectCategoryId");
  const defaultStartDate = moment().startOf("month").format("YYYY-MM-DD");
  const defaultEndDate = moment().endOf("month").format("YYYY-MM-DD");

  const setFilteredValues = useCallback(
    values => {
      const filterValues = JSON.parse(sessionStorage.getItem("filterValues"));

      const filteredDate = {
        startDate: isUndefined(filterValues?.startDate)
          ? defaultStartDate
          : filterValues.startDate,
        endDate: isUndefined(filterValues?.endDate)
          ? defaultEndDate
          : filterValues.endDate,
      };

      sessionStorage.setItem(
        "filterValues",
        JSON.stringify({
          path: "time-sheets",
          ...{ ...filterValues, ...filteredDate, ...values },
        })
      );
    },
    [defaultEndDate, defaultStartDate]
  );

  return (
    <Formik
      initialValues={{
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        projectId: !isNull(filterItems) ? filterItems.projectId : null,
        userId: !isCollaborator
          ? !isNull(filterItems)
            ? filterItems.userId
            : null
          : user.id,
        projectCategoryId: !isNull(filterItems)
          ? filterItems.projectCategoryId
          : null,
      }}
      onSubmit={values => {
        onHandleSubmit(
          values.projectId,
          values.userId,
          values.projectCategoryId,
          values.startDate,
          values.endDate
        );

        setFilteredValues({
          projectId: values.projectId,
          userId: values.userId,
          projectCategoryId: values.projectCategoryId,
        });

        setProjectDate([values.startDate, values.endDate]);
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Flex
            width={1}
            flexDirection={["column", "row"]}
            my={24}
            px={[12, 10, 0]}
          >
            {isAdmin && (
              <Box width={[1, 2 / 12]} mr={[0, 24]} mb={[15, 0]}>
                <Select
                  size="large"
                  split
                  allowClear
                  onClear={() => {
                    props.setFieldValue("projectId", null);
                  }}
                  value={props.values.projectId || 0}
                  onChange={value => {
                    props.setFieldValue("projectId", value);
                  }}
                  suffixIcon={
                    <ProjectOutlined style={{ color: baseColor.lightBlack }} />
                  }
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={0} disabled>
                    Choose Project
                  </Option>
                  {filteredProjects.map(project => (
                    <Option key={project.id} value={project.id}>
                      {project.name}
                    </Option>
                  ))}
                </Select>
              </Box>
            )}
            <Box width={[1, 2 / 12]} mr={[0, 24]} mb={[15, 0]}>
              <RangeDatePicker
                size="large"
                inputReadOnly
                width="100%"
                defaultValue={[
                  filterItems !== null
                    ? moment(filterItems.startDate, "YYYY-MM-DD")
                    : moment().startOf("month"),
                  filterItems !== null
                    ? moment(filterItems.endDate, "YYYY-MM-DD")
                    : moment().endOf("month"),
                ]}
                onClear={() => {
                  props.setFieldValue("startDate", null);
                  props.setFieldValue("endDate", null);
                }}
                onChange={date => {
                  const startDate = isEmpty(date)
                    ? null
                    : moment(date[0]).format("YYYY-MM-DD");
                  const endDate = isEmpty(date)
                    ? null
                    : moment(date[1]).format("YYYY-MM-DD");

                  props.setFieldValue("startDate", startDate);
                  props.setFieldValue("endDate", endDate);

                  setFilteredValues({
                    startDate:
                      startDate === null
                        ? moment().startOf("month").format("YYYY-MM-DD")
                        : startDate,
                    endDate:
                      endDate === null
                        ? moment().endOf("month").format("YYYY-MM-DD")
                        : endDate,
                  });
                }}
              />
            </Box>
            {isAdmin && (
              <Box width={[1, 2 / 12]} mr={[0, 24]} mb={[15, 0]}>
                <Select
                  size="large"
                  split
                  allowClear
                  showSearch
                  value={props.values.userId || 0}
                  onClear={() => {
                    props.setFieldValue("userId", null);
                  }}
                  onChange={value => {
                    props.setFieldValue("userId", value);
                  }}
                  suffixIcon={
                    <UserOutlined style={{ color: baseColor.lightBlack }} />
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={0} disabled>
                    Choose Employee
                  </Option>
                  <>
                    {filteredEmployees.map(employee => (
                      <Option key={employee.id} value={employee.id}>
                        {`${employee.firstname} ${employee.lastname}`}
                      </Option>
                    ))}
                  </>
                </Select>
              </Box>
            )}
            {isAdmin && (
              <Box width={[1, 2 / 12]} mr={[0, 24]} mb={[15, 0]}>
                <Select
                  size="large"
                  split
                  allowClear
                  showSearch
                  value={props.values.projectCategoryId || 0}
                  onClear={() => {
                    props.setFieldValue("projectCategoryId", null);
                  }}
                  onChange={value => {
                    props.setFieldValue("projectCategoryId", value);
                  }}
                  suffixIcon={
                    <GroupOutlined style={{ color: baseColor.lightBlack }} />
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={0} disabled>
                    Choose Category
                  </Option>

                  {filteredCategories.map(category => {
                    if (category.type === "Task") {
                      return (
                        <Option key={category.id} value={category.id}>
                          {category.name}
                        </Option>
                      );
                    }
                    if (
                      category.type === "Task" &&
                      projectCategoryID !== null
                    ) {
                      return (
                        <Option key={category.id} value={projectCategoryID}>
                          {category.name}
                        </Option>
                      );
                    }
                    return null;
                  })}
                </Select>
              </Box>
            )}
            <Box
              mr={[0, 24]}
              mb={[15, 0]}
              width={[1, 2 / 12]}
              justifyContent={["initial", "flex-end"]}
            >
              <Button size="large" block type="primary" htmlType="submit">
                Apply filters
              </Button>
            </Box>
            {isAdmin && (
              <Box width={[1, 2 / 12]} justifyContent={["initial", "flex-end"]}>
                <Button
                  size="large"
                  block
                  type="primary"
                  onClick={() => {
                    handlePrint();
                  }}
                >
                  Download PDF
                </Button>
              </Box>
            )}
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default Filter;
