import { message } from "antd";

import { employeeSalaryConstants } from "../../constants";
import http from "../../services/httpService";

export const getEmployeeSalaryAction = (
  id = null,
  startDate = null,
  endDate = null
) => {
  let endPoint = "/salaries";

  if (id !== null && startDate !== null && endDate !== null) {
    endPoint = `${endPoint}?projectId=${id}&startDate=${startDate}&endDate=${endDate}`;
  }
  if (id != null && startDate === null && endDate === null) {
    endPoint = `${endPoint}?projectId=${id}`;
  }
  if (id == null && startDate !== null && endDate !== null) {
    endPoint = `${endPoint}?startDate=${startDate}&endDate=${endDate}`;
  }

  return dispatch => {
    dispatch({ type: employeeSalaryConstants.GET_EMPLOYEE_SALARY_REQUEST });
    http
      .get(endPoint)
      .then(res => {
        dispatch({
          type: employeeSalaryConstants.GET_EMPLOYEE_SALARY_SUCCESS,
          payload: {
            employees: res.data.users,
            timeLogs: res.data.timeLogs,
          },
        });
      })
      .catch(() => {
        message.error("Unable to get employees data");
        dispatch({ type: employeeSalaryConstants.GET_EMPLOYEE_SALARY_FAILURE });
      });
  };
};
