/* eslint-disable react/destructuring-assignment */
import React, { useState, useCallback } from "react";

import { EditFilled } from "@ant-design/icons";
import { Tag, Badge } from "antd";
import { find } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as Yup from "yup";

import { baseColor } from "../../../../settings/foundations";
import {
  setProjectAction,
  resetProjectAction,
} from "../../../../stores/actions/project.actions";
import { Button } from "../../../atoms/Button";

export const useHelper = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [upsertType, setUpsertType] = useState("add");

  const { categories } = useSelector(state => state?.projectCategoryReducer);

  const handleCancel = () => {
    setIsOpen(false);
    dispatch(resetProjectAction());
  };

  const projectSchema = Yup.object().shape({
    name: Yup.string().required("Project name is a required field!"),
    status: Yup.bool().required("Status is a required field!"),
    category: Yup.string().required("Category is a required field!"),
  });

  const handleOpen = useCallback(
    (type, rowValues) => {
      setIsOpen(true);
      setUpsertType(type);
      if (type === "edit") {
        dispatch(setProjectAction(rowValues));
      }
    },
    [dispatch]
  );

  const columns = [
    {
      width: isMobile ? "40%" : "30%",
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      width: isMobile ? "40%" : "30%",
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: status => (
        <Badge
          status={status ? "success" : "error"}
          text={status ? "Active" : "Disabled"}
        />
      ),
    },
    {
      responsive: ["xl"],
      width: "30%",
      title: "Category",
      key: "category",
      dataIndex: "category",
      render: categoryId => {
        const computedCategory = find(categories, project => {
          return project.id === categoryId;
        });
        return (
          <Tag color="geekblue">{computedCategory?.name?.toUpperCase()}</Tag>
        );
      },
    },
    {
      width: isMobile ? "20%" : "10%",
      title: "Action",
      key: "action",
      render: (value, rowValues) => (
        <Button
          onClick={() => handleOpen("edit", rowValues)}
          icon={<EditFilled style={{ color: baseColor.primary }} />}
        />
      ),
    },
  ];

  return {
    isOpen,
    columns,
    upsertType,
    handleOpen,
    handleCancel,
    projectSchema,
  };
};
