/* eslint-disable react/jsx-boolean-value */
import React, { useEffect } from "react";

import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Formik, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { baseColor } from "../../../../settings/foundations";
import {
  addProjectAction,
  updateProjectAction,
} from "../../../../stores/actions/project.actions";
import { Button } from "../../../atoms/Button";
import { Flex, Box } from "../../../atoms/Flexbox";
import { Input } from "../../../atoms/Input";
import { Select, Option } from "../../../atoms/Select";
import { Text } from "../../../atoms/Typography";
import { useHelper } from "./useHelper";

const ProjectsForm = ({ handleCancel, upsertType }) => {
  const dispatch = useDispatch();
  const helper = useHelper();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });

  const { categories } = useSelector(state => state?.projectCategoryReducer);
  const { isLoading } = useSelector(state => state?.projectReducer);
  const { project } = useSelector(state => state?.projectReducer);

  useEffect(() => {
    if (isLoading) {
      handleCancel();
    }
  }, [handleCancel, isLoading]);

  return (
    <Formik
      validationSchema={helper.projectSchema}
      initialValues={project}
      onSubmit={values => {
        if (upsertType === "add") {
          dispatch(addProjectAction(values));
        } else if (upsertType === "edit") {
          dispatch(updateProjectAction(project.id, values));
        }
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Flex flexDirection="column" width={1} p={["8px", 16]}>
            <Input
              size="large"
              name="name"
              type="text"
              placeholder="Project Name"
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              value={props.values.name}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="name" />
            </Text>
          </Flex>
          <Flex width={1} flexDirection={["column", "row"]}>
            <Box width={[1, 1 / 2]} p={["8px", 16]}>
              <Select
                size="large"
                placeholder="Status"
                value={props.values.status}
                onChange={value => {
                  props.setFieldValue("status", value);
                }}
                onBlur={value => props.setFieldTouched("status", value)}
              >
                <Option value={true}>Active</Option>
                <Option value={false}>Disable</Option>
              </Select>
              <Text color={baseColor.red}>
                <ErrorMessage name="status" />
              </Text>
            </Box>
            <Box width={[1, 1 / 2]} p={["8px", 16]}>
              <Select
                size="large"
                placeholder="Category"
                value={props.values.category || 0}
                onChange={value => {
                  props.setFieldValue("category", value);
                }}
                onBlur={value => props.setFieldTouched("category", value)}
              >
                <Option value={0} disabled>
                  Select Category
                </Option>
                {categories.map(category => {
                  if (category.type === "Project") {
                    return (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    );
                  }
                  return null;
                })}
              </Select>
              <Text color={baseColor.red}>
                <ErrorMessage name="category" />
              </Text>
            </Box>
          </Flex>
          <Flex
            width={1}
            p={16}
            position={isTabletOrMobile ? "absolute" : "static"}
            bottom="110px"
            left="-10px"
            justifyContent="flex-end"
          >
            <Button
              size="large"
              type="default"
              icon={<CloseOutlined />}
              marginright={8}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              size="large"
              htmlType="submit"
              type="primary"
              loading={isLoading}
              icon={<CheckOutlined />}
            >
              Save
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default ProjectsForm;
