export const alertConstants = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",
};

export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  RESET_REQUEST: "RESET_REQUEST",
  RESET_SUCCESS: "RESET_SUCCESS",
  RESET_FAILURE: "RESET_FAILURE",

  SET_USERS_DETAILS: "SET_USERS_DETAILS",
};

export const projectConstants = {
  GET_PROJECT_REQUEST: "GET_PROJECT_REQUEST",
  GET_PROJECT_SUCCESS: "GET_PROJECT_SUCCESS",
  GET_PROJECT_FAILURE: "GET_PROJECT_FAILURE",

  ADD_PROJECT_REQUEST: "ADD_PROJECT_REQUEST",
  ADD_PROJECT_SUCCESS: "ADD_PROJECT_SUCCESS",
  ADD_PROJECT_FAILURE: "ADD_PROJECT_FAILURE",

  UPDATE_PROJECT_REQUEST: "UPDATE_PROJECT_REQUEST",
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",
  UPDATE_PROJECT_FAILURE: "UPDATE_PROJECT_FAILURE",

  SET_PROJECT_DATA: "SET_PROJECT_DATA",
  RESET_PROJECT_DATA: "RESET_PROJECT_DATA",
};

export const employeeConstants = {
  GET_EMPLOYEE_REQUEST: "GET_EMPLOYEE_REQUEST",
  GET_EMPLOYEE_SUCCESS: "GET_EMPLOYEE_SUCCESS",
  GET_EMPLOYEE_FAILURE: "GET_EMPLOYEE_FAILURE",

  ADD_EMPLOYEE_REQUEST: "ADD_EMPLOYEE_REQUEST",
  ADD_EMPLOYEE_SUCCESS: "ADD_EMPLOYEE_SUCCESS",
  ADD_EMPLOYEE_FAILURE: "ADD_EMPLOYEE_FAILURE",

  UPDATE_EMPLOYEE_REQUEST: "UPDATE_EMPLOYEE_REQUEST",
  UPDATE_EMPLOYEE_SUCCESS: "UPDATE_EMPLOYEE_SUCCESS",
  UPDATE_EMPLOYEE_FAILURE: "UPDATE_EMPLOYEE_FAILURE",

  SET_EMPLOYEE_DATA: "SET_EMPLOYEE_DATA",
  RESET_EMPLOYEE_DATA: "RESET_EMPLOYEE_DATA",
};

export const projectCategoryConstants = {
  GET_PROJECT_CATEGORY_REQUEST: "GET_PROJECT_CATEGORY_REQUEST",
  GET_PROJECT_CATEGORY_SUCCESS: "GET_PROJECT_CATEGORY_SUCCESS",
  GET_PROJECT_CATEGORY_FAILURE: "GET_PROJECT_CATEGORY_FAILURE",

  ADD_PROJECT_CATEGORY_REQUEST: "ADD_PROJECT_CATEGORY_REQUEST",
  ADD_PROJECT_CATEGORY_SUCCESS: "ADD_PROJECT_CATEGORY_SUCCESS",
  ADD_PROJECT_CATEGORY_FAILURE: "ADD_PROJECT_CATEGORY_FAILURE",

  UPDATE_PROJECT_CATEGORY_REQUEST: "UPDATE_PROJECT_CATEGORY_REQUEST",
  UPDATE_PROJECT_CATEGORY_SUCCESS: "UPDATE_PROJECT_CATEGORY_SUCCESS",
  UPDATE_PROJECT_CATEGORY_FAILURE: "UPDATE_PROJECT_CATEGORY_FAILURE",

  SET_PROJECT_CATEGORY_DATA: "SET_PROJECT_CATEGORY_DATA",
  RESET_PROJECT_CATEGORY_DATA: "RESET_PROJECT_CATEGORY_DATA",
};

export const projectTypeConstants = {
  GET_PROJECT_TYPE_REQUEST: "GET_PROJECT_TYPE_REQUEST",
  GET_PROJECT_TYPE_SUCCESS: "GET_PROJECT_TYPE_SUCCESS",
  GET_PROJECT_TYPE_FAILURE: "GET_PROJECT_TYPE_FAILURE",

  ADD_PROJECT_TYPE_REQUEST: "ADD_PROJECT_TYPE_REQUEST",
  ADD_PROJECT_TYPE_SUCCESS: "ADD_PROJECT_TYPE_SUCCESS",
  ADD_PROJECT_TYPE_FAILURE: "ADD_PROJECT_TYPE_FAILURE",

  UPDATE_PROJECT_TYPE_REQUEST: "UPDATE_PROJECT_TYPE_REQUEST",
  UPDATE_PROJECT_TYPE_SUCCESS: "UPDATE_PROJECT_TYPE_SUCCESS",
  UPDATE_PROJECT_TYPE_FAILURE: "UPDATE_PROJECT_TYPE_FAILURE",

  SET_PROJECT_TYPE_DATA: "SET_PROJECT_TYPE_DATA",
  RESET_PROJECT_TYPE_DATA: "RESET_PROJECT_TYPE_DATA",
};

export const commentsConstants = {
  ADD_COMMENT_REQUEST: "ADD_COMMENT_REQUEST",
  ADD_COMMENT_SUCCESS: "ADD_COMMENT_SUCCESS",
  ADD_COMMENT_FAILURE: "ADD_COMMENT_FAILURE",

  SET_TIME_ID: "SET_TIME_ID",

  SET_COMMENT_DATA: "SET_COMMENT_DATA",
  RESET_COMMENT_DATA: "RESET_COMMENT_DATA",

  UPDATE_COMMENT_REQUEST: "UPDATE_COMMENT_REQUEST",
  UPDATE_COMMENT_SUCCESS: "UPDATE_COMMENT_SUCCESS",
  UPDATE_COMMENT_FAILURE: "UPDATE_COMMENT_FAILURE",
};

export const rolesConstants = {
  GET_ROLES_REQUEST: "GET_ROLES_REQUEST",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE: "GET_ROLES_FAILURE",
};

export const employeeSalaryConstants = {
  GET_EMPLOYEE_SALARY_REQUEST: "GET_EMPLOYEE_SALARY_REQUEST",
  GET_EMPLOYEE_SALARY_SUCCESS: "GET_EMPLOYEE_SALARY_SUCCESS",
  GET_EMPLOYEE_SALARY_FAILURE: "GET_EMPLOYEE_SALARY_FAILURE",
};

export const employeeTimesheetConstants = {
  GET_EMPLOYEE_TIMESHEET_REQUEST: "GET_EMPLOYEE_TIMESHEET_REQUEST",
  GET_EMPLOYEE_TIMESHEET_SUCCESS: "GET_EMPLOYEE_TIMESHEET_SUCCESS",
  GET_EMPLOYEE_TIMESHEET_FAILURE: "GET_EMPLOYEE_TIMESHEET_FAILURE",

  UPDATE_EMPLOYEE_TIME_STATUS_REQUEST: "UPDATE_EMPLOYEE_TIME_STATUS_REQUEST",
  UPDATE_EMPLOYEE_TIME_STATUS_SUCCESS: "UPDATE_EMPLOYEE_TIME_STATUS_SUCCESS",
  UPDATE_EMPLOYEE_TIME_STATUS_FAILURE: "UPDATE_EMPLOYEE_TIME_STATUS_FAILURE",

  UPDATE_EMPLOYEE_TIME_REQUEST: "UPDATE_EMPLOYEE_TIME_REQUEST",
  UPDATE_EMPLOYEE_TIME_SUCCESS: "UPDATE_EMPLOYEE_TIME_SUCCESS",
  UPDATE_EMPLOYEE_TIME_FAILURE: "UPDATE_EMPLOYEE_TIME_FAILURE",

  DELETE_EMPLOYEE_TIME_REQUEST: "DELETE_EMPLOYEE_TIME_REQUEST",
  DELETE_EMPLOYEE_TIME_SUCCESS: "DELETE_EMPLOYEE_TIME_SUCCESS",
  DELETE_EMPLOYEE_TIME_FAILURE: "DELETE_EMPLOYEE_TIME_FAILURE",

  SET_TIMESHEET_DATA: "SET_TIMESHEET_DATA",
  RESET_TIMESHEET_DATA: "RESET_TIMESHEET_DATA",

  SET_CONFIRM_DELETE: "SET_CONFIRM_DELETE",
  RESET_CONFIRM_DELETE: "RESET_CONFIRM_DELETE",
};

export const statisticsConstants = {
  GET_STATISTICS_REQUEST: "GET_STATISTICS_REQUEST",
  GET_STATISTICS_SUCCESS: "GET_STATISTICS_SUCCESS",
  GET_STATISTICS_FAILURE: "GET_STATISTICS_FAILURE",
};

export const timeTrackingConstants = {
  ADD_TIME_REQUEST: "ADD_TIME_REQUEST",
  ADD_TIME_SUCCESS: "ADD_TIME_SUCCESS",
  ADD_TIME_FAILURE: "ADD_TIME_FAILURE",
};
