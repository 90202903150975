import React from "react";

import { ProjectOutlined, GroupOutlined } from "@ant-design/icons";
import { Formik, ErrorMessage } from "formik";
import { isEmpty, assign, sortBy } from "lodash";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as Yup from "yup";

import { baseColor } from "../../../settings/foundations";
import { addTimeLogAction } from "../../../stores/actions/timeTracking.action";
import { Button } from "../../atoms/Button";
import { DatePicker } from "../../atoms/DatePicker";
import { Box } from "../../atoms/Flexbox";
import { TextArea } from "../../atoms/Input";
import { Select, Option } from "../../atoms/Select";
import { TimePicker } from "../../atoms/TimePicker";
import { Text } from "../../atoms/Typography";

const TimeTrackingSchema = Yup.object().shape({
  date: Yup.string().required("Date is a required field!").nullable(),
  startTime: Yup.string()
    .required("Start time is a required field!")
    .nullable(),
  endTime: Yup.string()
    .test("is-greater", "end time should be greater", function (value) {
      const { startTime } = this.parent;
      return moment(value, "HH:mm a").isAfter(moment(startTime, "HH:mm a"));
    })
    .required("End time is a required field!")
    .nullable(),
  breakTime: Yup.string()
    .required("Break time is a required field!")
    .nullable(),
  ProjectId: Yup.string().required("Project is a required field!"),
  ProjectCategoryId: Yup.string().required(
    "Project category is a required field!"
  ),
  description: Yup.string().required("Description is a required field!"),
});

const Form = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
  const dispatch = useDispatch();

  const { user } = useSelector(state => state?.authReducer);
  const { projects } = useSelector(state => state?.projectReducer);
  const { categories } = useSelector(state => state?.projectCategoryReducer);
  const { submitting } = useSelector(state => state?.timeTrackingReducer);

  const filteredProjects = sortBy(
    projects.filter(item => item.status),
    "name"
  );
  const filteredCategories = sortBy(
    categories.filter(item => item.status),
    "name"
  );

  return (
    <Formik
      validationSchema={TimeTrackingSchema}
      initialValues={{
        date: moment(),
        startTime: "",
        endTime: "",
        breakTime: moment("01:00", "HH:mm").format("hh:mm:ss a"),
        ProjectId: "",
        ProjectCategoryId: "",
        description: "",
      }}
      onSubmit={(values, { resetForm }) => {
        const computedValues = assign(values, { UserId: user.id });
        dispatch(addTimeLogAction(computedValues, resetForm));
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Box marginBottom={[2, 11, 11]}>
            <Box marginBottom="0px">
              <Text
                fontSize={14}
                color={baseColor.label}
                texttransform="uppercase"
              >
                Choose date
              </Text>
            </Box>
            <DatePicker
              size="large"
              inputReadOnly
              name="date"
              width="100%"
              format="DD.MM.YYYY"
              value={
                isEmpty(props.values.date)
                  ? moment()
                  : moment(props.values.date, "YYYY-MM-DD")
              }
              onChange={value => {
                props.setFieldValue("date", value?.format("YYYY-MM-DD"));
              }}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="date" />
            </Text>
          </Box>

          <Box marginBottom={[2, 10, 10]}>
            <Box marginBottom="0px">
              <Text
                fontSize={14}
                color={baseColor.label}
                texttransform="uppercase"
              >
                Start time
              </Text>
            </Box>
            <TimePicker
              size="large"
              minuteStep={15}
              format="HH:mm"
              name="startTime"
              width="100%"
              placeholder=""
              allowClear
              inputReadOnly
              value={
                isEmpty(props.values.startTime)
                  ? null
                  : moment(props.values.startTime, "hh:mm:ss a")
              }
              onChange={value => {
                props.setFieldValue("startTime", value?.format("hh:mm:ss a"));
              }}
              onSelect={value => {
                props.setFieldValue("startTime", value?.format("hh:mm:ss a"));
              }}
            />

            <Text color={baseColor.red}>
              <ErrorMessage name="startTime" />
            </Text>
          </Box>

          <Box marginBottom={[2, 10, 10]}>
            <Box marginBottom="0px">
              <Text
                fontSize={14}
                color={baseColor.label}
                texttransform="uppercase"
              >
                End time
              </Text>
            </Box>
            <TimePicker
              size="large"
              inputReadOnly
              minuteStep={15}
              format="HH:mm"
              name="endTime"
              width="100%"
              placeholder=""
              value={
                isEmpty(props.values.endTime)
                  ? null
                  : moment(props.values.endTime, "hh:mm:ss a")
              }
              onChange={value => {
                props.setFieldValue("endTime", value?.format("hh:mm:ss a"));
              }}
              onSelect={value => {
                props.setFieldValue("endTime", value?.format("hh:mm:ss a"));
              }}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="endTime" />
            </Text>
          </Box>
          <Box marginBottom={[24, 36, 36]}>
            <Box marginBottom="0px">
              <Text
                fontSize={14}
                color={baseColor.label}
                texttransform="uppercase"
              >
                Break Time
              </Text>
            </Box>
            <TimePicker
              size="large"
              inputReadOnly
              minuteStep={15}
              format="HH:mm"
              name="breakTime"
              width="100%"
              disabledMinutes={() => {
                return moment(props.values.breakTime, "hh:mm:ss a").format(
                  "hh:mm:ss a"
                ) === moment("02:00", "HH:mm").format("hh:mm:ss a")
                  ? [15, 30, 45]
                  : null;
              }}
              popupClassName="breakTime"
              value={
                isEmpty(props.values.breakTime)
                  ? null
                  : moment(props.values.breakTime, "hh:mm:ss a")
              }
              onChange={value => {
                props.setFieldValue("breakTime", value?.format("hh:mm:ss a"));
              }}
              onSelect={value => {
                props.setFieldValue("breakTime", value?.format("hh:mm:ss a"));
              }}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="breakTime" />
            </Text>
          </Box>

          <Box marginBottom="0px">
            <Text
              fontSize={14}
              color={baseColor.label}
              texttransform="uppercase"
              marginbottom={10}
            >
              Details
            </Text>
          </Box>

          <Box marginBottom={[2, 16, 16]}>
            <Select
              size="large"
              split
              allowClear
              placeholder="Choose project"
              value={props.values.ProjectId || 0}
              onChange={value => {
                props.setFieldValue("ProjectId", value);
              }}
              onBlur={value => props.setFieldTouched("ProjectId", value)}
              suffixIcon={
                <ProjectOutlined style={{ color: baseColor.lightBlack }} />
              }
            >
              <Option value={0} disabled>
                Choose Project
              </Option>
              {filteredProjects.map(project => (
                <Option key={project.id} value={project.id}>
                  {project.name}
                </Option>
              ))}
            </Select>
            <Text color={baseColor.red}>
              <ErrorMessage name="ProjectId" />
            </Text>
          </Box>

          <Box marginBottom={[2, 16, 16]}>
            <Select
              split
              allowClear
              size="large"
              placeholder="Choose category"
              value={props.values.ProjectCategoryId || 0}
              onChange={value => {
                props.setFieldValue("ProjectCategoryId", value);
              }}
              onBlur={value =>
                props.setFieldTouched("ProjectCategoryId", value)
              }
              suffixIcon={
                <GroupOutlined style={{ color: baseColor.lightBlack }} />
              }
            >
              <Option value={0} disabled>
                Choose category
              </Option>
              {filteredCategories.map(category => {
                if (category.type === "Task") {
                  return (
                    <Option key={category.id} value={category.id}>
                      {category.name}
                    </Option>
                  );
                }
                return null;
              })}
            </Select>
            <Text color={baseColor.red}>
              <ErrorMessage name="ProjectCategoryId" />
            </Text>
          </Box>

          <Box marginBottom="21px">
            <TextArea
              size="large"
              rows={isTabletOrMobile ? 2 : 3}
              name="description"
              placeholder="What did you work on today?"
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              value={props.values.description}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="description" />
            </Text>
          </Box>

          <Button
            block
            type="primary"
            fontSize={16}
            size="large"
            htmlType="submit"
            loading={submitting}
          >
            Submit
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default Form;
