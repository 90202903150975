import React from "react";

import { Badge } from "antd";

import { addTimes } from "../../../helpers/util";

export const useHelper = () => {
  const columns = [
    {
      width: "30%",
      title: "Name",
      dataIndex: "firstname",
      render: (colValue, rowValue) =>
        `${rowValue.firstname} ${rowValue.lastname}`,
    },
    {
      width: "40%",
      title: "Status",
      dataIndex: "status",
      render: (colValue, rowValue) => {
        let status = true;
        for (let i = 0; i < rowValue.loggedTimes.length; i++) {
          if (rowValue.loggedTimes[i].status === false) status = false;
        }
        if (rowValue.loggedTimes.length === 0) {
          status = false;
        }
        return (
          <Badge
            status={status ? "success" : "error"}
            text={status ? "Approved" : "Unapproved"}
          />
        );
      },
    },
    {
      width: "30%",
      title: "Working hours",
      dataIndex: "hours",
      render: (colValue, rowValue) => {
        return addTimes(rowValue.loggedTimes);
      },
    },
  ];

  return { columns };
};
