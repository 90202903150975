import React from "react";

import {
  DashboardOutlined,
  FileDoneOutlined,
  DollarOutlined,
  SettingOutlined,
  MenuOutlined,
  // ClockCircleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { Flex } from "../../atoms/Flexbox";
import TimeTrackUpsert from "../../organisms/TimeTracking/upsert";
import Language from "../Language";
import User from "../User";
import {
  Header,
  LogoWrapper,
  MenuList,
  MobileView,
  Hamberger,
  Drawer,
} from "./header.styles";
import MenuLink from "./menuLink";

const AppHeader = ({ theme, isprimary }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1200 });
  const [visible, setVisible] = React.useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const history = useHistory();

  const { user } = useSelector(state => state?.authReducer);

  const isManager = user?.role === "MANAGER";
  const isAdmin = user?.role === "SUPER ADMIN" || user?.role === "ADMIN";
  const isCollaborator = user?.role === "COLLABORATOR";

  const menu = [
    {
      title: "Statistics",
      path: "/statistics",
      icon: <DashboardOutlined />,
      show: isAdmin || isManager,
    },
    {
      title: "Timesheets",
      path: "/time-sheets",
      icon: <FileDoneOutlined />,
      show: isAdmin || isManager || isCollaborator,
    },
    {
      title: "Salaries",
      path: "/salaries",
      icon: <DollarOutlined />,
      show: isAdmin || isManager,
    },
    // {
    //   title: "Time Tracking",
    //   path: "/time-tracking",
    //   icon: <ClockCircleOutlined />,
    //   show: isTabletOrMobile && (isAdmin || isManager),
    // },
    {
      title: "Settings",
      path: "/settings",
      icon: <SettingOutlined />,
      show: isAdmin,
    },
  ];

  return (
    <Header theme={theme}>
      <LogoWrapper>
        <Link to="/">
          <Logo />
        </Link>
      </LogoWrapper>
      {isprimary && (
        <Flex width={1} justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <MenuList>
              {menu.map(
                item =>
                  item.show && (
                    <MenuLink
                      key={item.title}
                      className={
                        history.location.pathname === item.path ? "active" : ""
                      }
                      icon={item.icon}
                      title={item.title}
                      path={item.path}
                    />
                  )
              )}
            </MenuList>

            {!isTabletOrMobile && <TimeTrackUpsert />}
          </Flex>
          <Flex alignItems="center">
            <Language />
            <User />
            {isTabletOrMobile && (isManager || isAdmin) && (
              <MobileView>
                <Hamberger
                  type="button"
                  onClick={() => {
                    showDrawer();
                  }}
                >
                  <MenuOutlined style={{ fontSize: "16px" }} />
                </Hamberger>
                <Drawer
                  placement="right"
                  closable={false}
                  onClose={onClose}
                  visible={visible}
                  key="right"
                >
                  <MenuList mobile="true">
                    {menu.map(
                      item =>
                        item.show && (
                          <MenuLink
                            key={item.title}
                            className={
                              history.location.pathname === item.path
                                ? "active"
                                : ""
                            }
                            icon={item.icon}
                            title={item.title}
                            path={item.path}
                          />
                        )
                    )}
                  </MenuList>
                </Drawer>
              </MobileView>
            )}
          </Flex>
        </Flex>
      )}
    </Header>
  );
};

export default AppHeader;
