import update from "immutability-helper";

import { projectCategoryConstants } from "../../constants";

const category = { name: "", status: true, type: "" };

export default function projectCategoryReducer(
  state = { categories: [], category },
  action
) {
  switch (action.type) {
    case projectCategoryConstants.GET_PROJECT_CATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case projectCategoryConstants.GET_PROJECT_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.payload,
      };
    case projectCategoryConstants.GET_PROJECT_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case projectCategoryConstants.ADD_PROJECT_CATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case projectCategoryConstants.ADD_PROJECT_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, action.payload],
        isLoading: false,
      };
    case projectCategoryConstants.ADD_PROJECT_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case projectCategoryConstants.UPDATE_PROJECT_CATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case projectCategoryConstants.UPDATE_PROJECT_CATEGORY_SUCCESS:
      const itemIndex = state.categories.findIndex(
        singleCategory => singleCategory.id === action.id
      );

      return {
        ...state,
        isLoading: false,
        categories: update(state.categories, {
          [itemIndex]: { $set: action.payload },
        }),
      };
    case projectCategoryConstants.UPDATE_PROJECT_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case projectCategoryConstants.SET_PROJECT_CATEGORY_DATA:
      return {
        ...state,
        category: action.payload,
        isLoading: false,
      };
    case projectCategoryConstants.RESET_PROJECT_CATEGORY_DATA:
      return {
        ...state,
        category,
        isLoading: false,
      };
    default:
      return state;
  }
}
