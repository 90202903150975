import { combineReducers } from "redux";

import authReducer from "./auth.reducer";
import commentsReducer from "./comment.reducer";
import employeeReducer from "./employee.reducer";
import resetPasswordReducer from "./forgotPassword.reducer";
import projectReducer from "./project.reducer";
import projectCategoryReducer from "./projectCategory.reducer";
import projectTypeReducer from "./projectType.reducer";
import rolesReducer from "./role.reducer";
import salaryReducer from "./salary.reducer";
import statisticsReducer from "./statistics.reducer";
import timeSheetReducer from "./timeSheet.reducer";
import timeTrackingReducer from "./timeTracking.reducer";

const rootReducer = combineReducers({
  authReducer,
  projectReducer,
  employeeReducer,
  projectCategoryReducer,
  projectTypeReducer,
  resetPasswordReducer,
  rolesReducer,
  salaryReducer,
  timeSheetReducer,
  statisticsReducer,
  timeTrackingReducer,
  commentsReducer,
});

export default rootReducer;
