import { Global } from "@emotion/react";
import { useMediaQuery } from "react-responsive";

export const GlobalStyle = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
  return (
    <Global
      styles={{
        ".table-column-text-align": {
          verticalAlign: "top",
        },
        ".table-action-column": {
          paddingTop: "12px !important",
        },
        ".hide-mobile": {
          display: "none",
        },
        ".ant-picker-panels": {
          flexDirection: isTabletOrMobile ? "column" : "row",
        },
        ".datepicker-modal": {
          position: "fixed",
          zIndex: "1000",
        },
        ".ant-modal-content": {
          width: isTabletOrMobile ? "100%" : "auto",
          height: isTabletOrMobile ? "100vh" : "auto",
          position: isTabletOrMobile ? "fixed" : "relative",
          top: 0,
          left: 0,
          overflowY: "auto",
        },
        "input[type=time]::-webkit-calendar-picker-indicator": {
          background: "none",
        },
        ".breakTime": {
          "ul:first-child": {
            "li:nth-of-type(1n+4)": { display: "none" },
          },
        },
        "@media screen": { ".pdf-component": { visibility: "hidden" } },
        "@media print": {
          ".table-breaked": {
            pageBreakBefore: "auto",
          },
        },
      }}
    />
  );
};
