import { message } from "antd";

import { commentsConstants } from "../../constants";
import http from "../../services/httpService";

export function addCommentAction(payload) {
  return dispatch => {
    dispatch({ type: commentsConstants.ADD_COMMENT_REQUEST });
    http
      .post("/comments/add", payload)
      .then(res => {
        message.success("Comment added successfully!");
        dispatch({
          type: commentsConstants.ADD_COMMENT_SUCCESS,
          payload: res.data.comment,
        });
      })
      .catch(() => {
        message.error("Something went wrong. unable to add comment.");
        dispatch({
          type: commentsConstants.ADD_COMMENT_FAILURE,
        });
      });
  };
}

export function setLoggedTimeIdAction(updatedFormValues) {
  return dispatch => {
    dispatch({
      type: commentsConstants.SET_TIME_ID,
      payload: updatedFormValues,
    });
  };
}

export function setCommentAction(data) {
  return dispatch => {
    dispatch({ type: commentsConstants.SET_COMMENT_DATA, payload: data });
  };
}

export function resetCommentAction() {
  return dispatch => {
    dispatch({ type: commentsConstants.RESET_COMMENT_DATA });
  };
}

export function updateCommentAction(payload) {
  return dispatch => {
    dispatch({ type: commentsConstants.UPDATE_COMMENT_REQUEST });
    http
      .patch(`/comments/${payload.id}/${payload.UserId}`, {
        UserId: payload.UserId,
        LoggedTimeId: payload.LoggedTimeId,
        comment: payload.comment,
      })
      .then(() => {
        message.success("Comment updated successfully!");
        dispatch({
          type: commentsConstants.UPDATE_COMMENT_SUCCESS,
        });
      })
      .catch(() => {
        message.error("Something went wrong. unable to update comment.");
        dispatch({
          type: commentsConstants.UPDATE_COMMENT_FAILURE,
        });
      });
  };
}
