import { message } from "antd";

import { statisticsConstants } from "../../constants";
import http from "../../services/httpService";

export const getStatisticsAction = (
  projectId = null,
  userId = null,
  projectCategoryId = null,
  startDate = null,
  endDate = null
) => {
  let endPoint = "/statistics";

  let counter = 0;
  const array = [projectId, userId, projectCategoryId, startDate, endDate];

  for (let i = 0; i < array.length; i++) {
    if (array[i] !== null) {
      counter += 1;
      if (counter <= 1) {
        endPoint += "?";
      } else endPoint += "&";
      if (i === 0) endPoint += "projectId=";
      if (i === 1) endPoint += "userId=";
      if (i === 2) endPoint += "projectCategoryId=";
      if (i === 3) endPoint += "startDate=";
      if (i === 4) endPoint += "endDate=";
      endPoint += array[i];
    }
  }

  return dispatch => {
    dispatch({ type: statisticsConstants.GET_STATISTICS_REQUEST });
    http
      .get(endPoint)
      .then(res => {
        dispatch({
          type: statisticsConstants.GET_STATISTICS_SUCCESS,
          payload: {
            employees: res.data.users,
            totalHours: res.data.totalHours,
            totalApprovedHours: res.data.totalApprovedHours,
            totalUnApprovedHours: res.data.totalUnApprovedHours,
            averageHoursPerEmployee: res.data.averageHoursPerEmployee,
            timeLogs: res.data.timeLogs,
            usersCount: res.data.usersCount,
            approvedTimeLogs: res.data.approvedTimeLogs,
            unApprovedTimeLogs: res.data.unApprovedTimeLogs,
          },
        });
      })
      .catch(() => {
        message.error("Unable to get employees data");
        dispatch({ type: statisticsConstants.GET_STATISTICS_FAILURE });
      });
  };
};
