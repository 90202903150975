import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import Timesheets from "../components/organisms/TimeSheets";
import AppLayout from "../Layout";
import { checkAuth } from "../stores/actions/auth.actions";

const TimeSheetsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  return (
    <AppLayout theme="primary" isprimary>
      <Timesheets />
    </AppLayout>
  );
};

export default TimeSheetsPage;
