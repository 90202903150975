import { message } from "antd";

import { employeeTimesheetConstants } from "../../constants";
import http from "../../services/httpService";

export const getEmployeeTimeSheetsAction = (
  projectId = null,
  userId = null,
  projectCategoryId = null,
  startDate = null,
  endDate = null
) => {
  let endPoint = "/timesheets";

  let counter = 0;
  const array = [projectId, userId, projectCategoryId, startDate, endDate];

  for (let i = 0; i < array.length; i++) {
    if (array[i] !== null) {
      counter += 1;
      if (counter <= 1) {
        endPoint += "?";
      } else endPoint += "&";
      if (i === 0) endPoint += "projectId=";
      if (i === 1) endPoint += "userId=";
      if (i === 2) endPoint += "projectCategoryId=";
      if (i === 3) endPoint += "startDate=";
      if (i === 4) endPoint += "endDate=";
      endPoint += array[i];
    }
  }

  return dispatch => {
    dispatch({
      type: employeeTimesheetConstants.GET_EMPLOYEE_TIMESHEET_REQUEST,
    });
    http
      .get(endPoint)
      .then(res => {
        dispatch({
          type: employeeTimesheetConstants.GET_EMPLOYEE_TIMESHEET_SUCCESS,
          payload: {
            employees: res.data.users,
          },
        });
      })
      .catch(() => {
        message.error("Unable to get employees data");
        dispatch({
          type: employeeTimesheetConstants.GET_EMPLOYEE_TIMESHEET_FAILURE,
        });
      });
  };
};

export const approveEmployeeLoggedTime = loggedTimeIds => {
  return dispatch => {
    dispatch({
      type: employeeTimesheetConstants.UPDATE_EMPLOYEE_TIME_STATUS_REQUEST,
    });

    http
      .patch("/loggedTime/statusUpdate", { loggedTimes: loggedTimeIds })
      .then(res => {
        dispatch({
          type: employeeTimesheetConstants.UPDATE_EMPLOYEE_TIME_STATUS_SUCCESS,
          payload: res.data.time,
        });
      })
      .catch(() => {
        message.error("Unable to approve time logs");
        dispatch({
          type: employeeTimesheetConstants.UPDATE_EMPLOYEE_TIME_STATUS_FAILURE,
        });
      });
  };
};

export function updateTimesheetAction(uid, payload) {
  return dispatch => {
    dispatch({ type: employeeTimesheetConstants.UPDATE_EMPLOYEE_TIME_REQUEST });
    http
      .patch(`/loggedTime/${uid}`, payload)
      .then(res => {
        message.success("Timesheet data updated successfully!");
        dispatch({
          type: employeeTimesheetConstants.UPDATE_EMPLOYEE_TIME_SUCCESS,
          id: uid,
          payload: res.data.time,
        });
      })
      .catch(() => {
        message.error("Something went wrong. unable to update Timesheet data");
        dispatch({
          type: employeeTimesheetConstants.UPDATE_EMPLOYEE_TIME_FAILURE,
        });
      });
  };
}
export function deleteTimesheetAction(uid) {
  return dispatch => {
    dispatch({ type: employeeTimesheetConstants.DELETE_EMPLOYEE_TIME_REQUEST });
    http
      .delete(`/loggedTime/${uid}`)
      .then(res => {
        message.success("Time log deleted successfully!");
        dispatch({
          type: employeeTimesheetConstants.DELETE_EMPLOYEE_TIME_SUCCESS,
          payload: res.data.time,
        });
      })
      .catch(() => {
        message.error("Something went wrong. unable to delete time log");
        dispatch({
          type: employeeTimesheetConstants.DELETE_EMPLOYEE_TIME_FAILURE,
        });
      });
  };
}

export function setTimeSheetAction(data) {
  return dispatch => {
    dispatch({
      type: employeeTimesheetConstants.SET_TIMESHEET_DATA,
      payload: data,
    });
  };
}

export function resetTimeSheetAction() {
  return dispatch => {
    dispatch({ type: employeeTimesheetConstants.RESET_TIMESHEET_DATA });
  };
}

export function setConfirmDeleteAction(uid) {
  return dispatch => {
    dispatch({
      type: employeeTimesheetConstants.SET_CONFIRM_DELETE,
      payload: uid,
    });
  };
}

export function resetConfirmDeleteAction() {
  return dispatch => {
    dispatch({ type: employeeTimesheetConstants.RESET_CONFIRM_DELETE });
  };
}
