import React from "react";

import { ClockCircleOutlined, FileDoneOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import { Flex, Box } from "../../atoms/Flexbox";
import MenuLink from "./menuLink";
import { Affix } from "./tabbar.styles";

const TabBar = () => {
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 992 });

  return isTabletOrMobile ? (
    <Affix offsetBottom={0} width={1}>
      <Flex py="10px" width={1} style={{ backgroundColor: "white" }}>
        <Box width={1 / 2} borderRight="1px solid #DFE0EB">
          <MenuLink
            title="Time Tracking"
            path="/time-tracking"
            icon={<ClockCircleOutlined />}
            className={
              history.location.pathname === "/time-tracking" ? "active" : ""
            }
          />
        </Box>
        <Box width={1 / 2}>
          <MenuLink
            title="Timesheets"
            path="/time-sheets"
            icon={<FileDoneOutlined />}
            className={
              history.location.pathname === "/time-sheets" ? "active" : ""
            }
          />
        </Box>
      </Flex>
    </Affix>
  ) : null;
};

export default TabBar;
