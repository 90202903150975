import React, { useEffect } from "react";

import {
  CloseOutlined,
  CheckOutlined,
  ProjectOutlined,
  GroupOutlined,
  MessageFilled,
} from "@ant-design/icons";
import { Formik, ErrorMessage } from "formik";
import { isEmpty, find, sortBy } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { baseColor } from "../../../settings/foundations";
import { Button } from "../../atoms/Button";
import { DatePicker } from "../../atoms/DatePicker";
import { Flex, Box } from "../../atoms/Flexbox";
import { TextArea } from "../../atoms/Input";
import { Select, Option } from "../../atoms/Select";
import { TimePicker } from "../../atoms/TimePicker";
import { Title, Text, Paragraph } from "../../atoms/Typography";
import { useHelper } from "./useHelper";

const TimeSheetForm = ({ handleCancel, onSubmit, initialValues }) => {
  const isMobile = useMediaQuery({ maxWidth: 1023 });

  const { isLoading } = useSelector(state => state?.timeSheetReducer);
  const { timeSheet } = useSelector(state => state?.timeSheetReducer);
  const { projects } = useSelector(state => state?.projectReducer);
  const { employees } = useSelector(state => state?.employeeReducer);
  const { categories } = useSelector(state => state?.projectCategoryReducer);
  const helper = useHelper();

  const filteredProjects = sortBy(
    projects.filter(item => item.status),
    "name"
  );
  const filteredCategories = sortBy(
    categories.filter(item => item.status),
    "name"
  );

  useEffect(() => {
    if (isLoading) {
      handleCancel();
    }
  }, [handleCancel, isLoading]);

  return (
    <Formik
      validationSchema={helper.TimeTrackingSchema}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values, resetForm);
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Flex width={1} flexDirection={["column", "row"]}>
            <Box width={[1, 1 / 2]} px={["8px", 16]} marginBottom={[2, 10, 10]}>
              <Box marginBottom="0px">
                <Text
                  fontSize={14}
                  color={baseColor.label}
                  texttransform="uppercase"
                >
                  Project
                </Text>
              </Box>
              <Select
                size="large"
                split
                allowClear
                placeholder=""
                value={props.values.ProjectId || 0}
                onChange={value => {
                  props.setFieldValue("ProjectId", value);
                }}
                onBlur={value => props.setFieldTouched("ProjectId", value)}
                suffixIcon={
                  <ProjectOutlined style={{ color: baseColor.lightBlack }} />
                }
              >
                <Option value={0} disabled>
                  Choose Project
                </Option>
                {filteredProjects.map(project => (
                  <Option key={project.id} value={project.id}>
                    {project.name}
                  </Option>
                ))}
              </Select>
              <Text color={baseColor.red}>
                <ErrorMessage name="ProjectId" />
              </Text>
            </Box>

            <Box width={[1, 1 / 2]} px={["8px", 16]} marginBottom={[2, 10, 10]}>
              <Box marginBottom="0px">
                <Text
                  fontSize={14}
                  color={baseColor.label}
                  texttransform="uppercase"
                >
                  Category
                </Text>
              </Box>
              <Select
                size="large"
                split
                allowClear
                placeholder=""
                value={props.values.ProjectCategoryId || 0}
                onChange={value => {
                  props.setFieldValue("ProjectCategoryId", value);
                }}
                onBlur={value =>
                  props.setFieldTouched("ProjectCategoryId", value)
                }
                suffixIcon={
                  <GroupOutlined style={{ color: baseColor.lightBlack }} />
                }
              >
                <Option value={0} disabled>
                  Choose Category
                </Option>
                {filteredCategories.map(category => {
                  if (category.type === "Task") {
                    return (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    );
                  }
                  return null;
                })}
              </Select>
              <Text color={baseColor.red}>
                <ErrorMessage name="ProjectCategoryId" />
              </Text>
            </Box>
          </Flex>
          <Flex width={1} flexDirection={["column", "row"]}>
            <Box width={1} px={["8px", 16]} marginBottom={[2, 10, 10]}>
              <Box marginBottom="0px">
                <Text
                  fontSize={14}
                  color={baseColor.label}
                  texttransform="uppercase"
                >
                  Choose Date
                </Text>
              </Box>
              <DatePicker
                size="large"
                inputReadOnly
                name="date"
                width="100%"
                placeholder=""
                format="DD.MM.YYYY"
                value={
                  isEmpty(props.values.date)
                    ? null
                    : moment(props.values.date, "YYYY-MM-DD")
                }
                onChange={value => {
                  props.setFieldValue("date", value?.format("YYYY-MM-DD"));
                }}
              />
              <Text color={baseColor.red}>
                <ErrorMessage name="date" />
              </Text>
            </Box>
          </Flex>
          <Flex width={1} flexDirection={["column", "row"]}>
            <Box width={[1, 1 / 2]} px={["8px", 16]} marginBottom={[2, 10, 10]}>
              <Box marginBottom="0px">
                <Text
                  fontSize={14}
                  color={baseColor.label}
                  texttransform="uppercase"
                >
                  Start time
                </Text>
              </Box>
              <TimePicker
                size="large"
                inputReadOnly
                minuteStep={15}
                format="HH:mm"
                name="startTime"
                width="100%"
                placeholder=""
                value={
                  isEmpty(props.values.startTime)
                    ? null
                    : moment(props.values.startTime, "hh:mm:ss a")
                }
                onChange={value => {
                  props.setFieldValue("startTime", value?.format("hh:mm:ss a"));
                }}
                onSelect={value => {
                  props.setFieldValue("startTime", value?.format("hh:mm:ss a"));
                }}
              />
              <Text color={baseColor.red}>
                <ErrorMessage name="startTime" />
              </Text>
            </Box>
            <Box width={[1, 1 / 2]} px={["8px", 16]} marginBottom={[2, 10, 10]}>
              <Box marginBottom="0px">
                <Text
                  fontSize={14}
                  color={baseColor.label}
                  texttransform="uppercase"
                >
                  End time
                </Text>
              </Box>
              <TimePicker
                size="large"
                inputReadOnly
                minuteStep={15}
                format="HH:mm"
                name="endTime"
                width="100%"
                placeholder=""
                value={
                  isEmpty(props.values.endTime)
                    ? null
                    : moment(props.values.endTime, "hh:mm:ss a")
                }
                onChange={value => {
                  props.setFieldValue("endTime", value?.format("hh:mm:ss a"));
                }}
                onSelect={value => {
                  props.setFieldValue("endTime", value?.format("hh:mm:ss a"));
                }}
              />
              <Text color={baseColor.red}>
                <ErrorMessage name="endTime" />
              </Text>
            </Box>
          </Flex>
          <Flex width={1} px={["8px", 16]} flexDirection="column">
            <Box marginBottom="0px">
              <Text
                fontSize={14}
                color={baseColor.label}
                texttransform="uppercase"
              >
                Break Time
              </Text>
            </Box>
            <TimePicker
              size="large"
              inputReadOnly
              minuteStep={15}
              format="HH:mm"
              name="breakTime"
              width="100%"
              placeholder=""
              disabledMinutes={() => {
                return moment(props.values.breakTime, "hh:mm:ss a").format(
                  "hh:mm:ss a"
                ) === moment("02:00", "HH:mm").format("hh:mm:ss a")
                  ? [15, 30, 45]
                  : null;
              }}
              popupClassName="breakTime"
              value={
                isEmpty(props.values.breakTime)
                  ? null
                  : moment(props.values.breakTime, "hh:mm:ss a")
              }
              onChange={value => {
                props.setFieldValue("breakTime", value?.format("hh:mm:ss a"));
              }}
              onSelect={value => {
                props.setFieldValue("breakTime", value?.format("hh:mm:ss a"));
              }}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="breakTime" />
            </Text>
          </Flex>
          <Flex width={1} p={["8px", 16]} flexDirection="column">
            <Box marginBottom="0px">
              <Text
                fontSize={14}
                color={baseColor.label}
                texttransform="uppercase"
              >
                Description
              </Text>
            </Box>
            <TextArea
              size="large"
              rows={4}
              name="description"
              placeholder="Description"
              onBlur={e => {
                props.handleBlur(e);
              }}
              onChange={props.handleChange}
              value={props.values.description}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="description" />
            </Text>
          </Flex>

          {isMobile && (
            <Flex width={1} p={["8px", 16]} mt="8px" flexDirection="column">
              <Flex width={1} flexDirection="row">
                <MessageFilled
                  width="14px"
                  height="14px"
                  style={{
                    color: baseColor.red,
                    marginRight: "10px",
                    marginTop: "2px",
                  }}
                />
                <Title
                  color={baseColor.black}
                  fontSize={16}
                  fontWeight={500}
                  marginbottom={0}
                >
                  COMMENTS
                </Title>
              </Flex>
              {props.values?.comments?.map(singleComment => {
                const computedUser = find(
                  employees,
                  employee => employee.id === singleComment.UserId
                );

                return (
                  <Flex width={1} py="8px" mb={2}>
                    <Box flexDirection="column">
                      <Title
                        color={baseColor.black}
                        fontSize={12}
                        fontWeight={500}
                        marginbottom={0}
                      >
                        {moment(timeSheet.createdAt).format("DD MMMM hh:mm")},{" "}
                        {`${computedUser?.firstname} ${computedUser?.lastname}`}
                      </Title>

                      <Paragraph
                        color={baseColor.black}
                        fontSize={12}
                        fontWeight={400}
                        marginbottom={0}
                      >
                        {singleComment.comment}
                      </Paragraph>
                    </Box>
                  </Flex>
                );
              })}
            </Flex>
          )}

          <Flex
            width={1}
            p={["8px", 16]}
            bottom="110px"
            left="-10px"
            mt={[5, 0]}
            justifyContent="flex-end"
          >
            <Button
              size="large"
              type="default"
              marginright={8}
              onClick={handleCancel}
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>
            <Button
              size="large"
              htmlType="submit"
              type="primary"
              loading={isLoading}
              icon={<CheckOutlined />}
              onClick={handleCancel}
            >
              Save
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default TimeSheetForm;
