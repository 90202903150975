import React from "react";

import { Link } from "react-router-dom";

import { MenuItem } from "./tabbar.styles";

const MenuLink = ({ title, icon, path, className }) => {
  return (
    <MenuItem>
      <Link to={path} className={className}>
        <div>
          <div>{icon}</div>
          <div>{title}</div>
        </div>
      </Link>
    </MenuItem>
  );
};

export default MenuLink;
