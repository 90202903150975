export const baseColor = {
  black: "#000000",
  lightBlack: "#262626",
  darkGray: "#595959",
  light: "#434343",
  gray: "#434343",
  white: "#ffffff",
  primary: "#1890FF",
  lightGray: "#F7F9FD",
  midGray: "#D9D9D9",
  lightRed: "#FFF1F0",
  red: "#F5222D",
  darkRed: "#DD3E45",
  lightBlue: "#F0F5FF",
  darkBlue: "#0050B3",
  blue: "#096DD9",
  green: "#52C41A",

  lightText: "#9FA2B4",
  head: "#252733",
  label: "#BFBFBF",

  infoBg: "rgba(230, 247, 255, 1)",
};
