import React from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@emotion/react";
import { Provider } from "react-redux";

import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./routes";
import { GlobalStyle } from "./settings/global";
import { theme } from "./settings/theme";
import store from "./stores/store";
import "antd/dist/antd.css";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <GlobalStyle />
      <AppRoutes />
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
