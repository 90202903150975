import React from "react";

import ForgotPassword from "../components/organisms/ForgotPassword";
import AppLayout from "../Layout";

const ForgotPasswordPage = () => {
  return (
    <AppLayout theme="absolute">
      <ForgotPassword />
    </AppLayout>
  );
};

export default ForgotPasswordPage;
