import React, { useState, useCallback } from "react";

import { EditFilled } from "@ant-design/icons";
import { Badge } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as Yup from "yup";

import { baseColor } from "../../../../settings/foundations";
import {
  setEmployeeAction,
  resetEmployeeAction,
} from "../../../../stores/actions/employee.action";
import { Button } from "../../../atoms/Button";

export const useHelper = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [upsertType, setUpsertType] = useState("add");

  const handleCancel = () => {
    setIsOpen(false);
    dispatch(resetEmployeeAction());
  };

  const handleOpen = useCallback(
    (type, rowValues) => {
      setIsOpen(true);
      setUpsertType(type);
      if (type === "edit") {
        dispatch(setEmployeeAction(rowValues));
      }
    },
    [dispatch]
  );

  const columns = [
    {
      width: isMobile ? "40%" : "20%",
      title: "Name",
      dataIndex: "firstname",
      key: "firstname",
      render: (firstName, rowValues) => `${firstName} ${rowValues.lastname}`,
    },

    {
      width: isMobile ? "40%" : "15%",
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: status => (
        <Badge
          status={status ? "success" : "error"}
          text={status ? "Active" : "Disabled"}
        />
      ),
    },
    {
      responsive: ["xl"],
      width: "20%",
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      responsive: ["xl"],
      width: "15%",
      title: "Date of birth",
      key: "dob",
      dataIndex: "dob",
      render: dob => moment(dob).format("DD.MM.YYYY"),
    },
    {
      responsive: ["xl"],
      width: "20%",
      title: "Role",
      key: "role",
      dataIndex: "role",
    },
    {
      width: isMobile ? "20%" : "10%",
      title: "Action",
      key: "action",
      render: (value, rowValues) => (
        <Button
          onClick={() => handleOpen("edit", rowValues)}
          icon={<EditFilled style={{ color: baseColor.primary }} />}
        />
      ),
    },
  ];

  const employeesSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is a required field!"),
    email: Yup.string()
      .email("Please add a valid email.")
      .required("Email is a required field!"),
    status: Yup.bool().required("Status is a required field!"),
    dob: Yup.string().required("Date of Birth is a required field!").nullable(),
    role: Yup.string().required("Role is a required field!"),
  });

  return {
    isOpen,
    columns,
    handleOpen,
    handleCancel,
    upsertType,
    employeesSchema,
  };
};
