import update from "immutability-helper";

import { projectConstants } from "../../constants";

const project = { name: "", status: true, category: "" };

export default function projectReducer(
  state = { projects: [], project },
  action
) {
  switch (action.type) {
    case projectConstants.GET_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case projectConstants.GET_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        projects: action.payload,
      };
    case projectConstants.GET_PROJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case projectConstants.ADD_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case projectConstants.ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projects: [...state.projects, action.payload],
        isLoading: false,
      };
    case projectConstants.ADD_PROJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case projectConstants.UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case projectConstants.UPDATE_PROJECT_SUCCESS:
      const itemIndex = state.projects.findIndex(
        singleProject => singleProject.id === action.id
      );

      return {
        ...state,
        isLoading: false,
        projects: update(state.projects, {
          [itemIndex]: { $set: action.payload },
        }),
      };
    case projectConstants.UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case projectConstants.SET_PROJECT_DATA:
      return {
        ...state,
        project: action.payload,
        isLoading: false,
      };
    case projectConstants.RESET_PROJECT_DATA:
      return {
        ...state,
        project,
        isLoading: false,
      };
    default:
      return state;
  }
}
