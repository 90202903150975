import { userConstants } from "../../constants";

export default function resetPasswordReducer(state = {}, action) {
  switch (action.type) {
    case userConstants.RESET_REQUEST:
      return {
        isLoading: true,
      };
    case userConstants.RESET_SUCCESS:
      return {
        isLoading: false,
      };
    case userConstants.RESET_FAILURE:
      return {
        isLoading: false,
      };
    default:
      return state;
  }
}
