import React from "react";

import { ClockCircleFilled } from "@ant-design/icons";

import { ReactComponent as TimerIcon } from "../../../assets/icons/Timer.svg";
import { baseColor } from "../../../settings/foundations";
import { Button } from "../../atoms/Button";
import { Flex, Box } from "../../atoms/Flexbox";
import { Popover } from "../../atoms/Popover";
import Form from "./form";

const Upsert = () => {
  return (
    <Popover
      trigger="click"
      placement="bottom"
      content={
        <Flex width={1} flexDirection="column" px="20px" pb="8px">
          <Box width={1} textAlign="center">
            <TimerIcon height="150" width="150" />
          </Box>
          <Form />
        </Flex>
      }
      overlayStyle={{
        width: "408px",
      }}
    >
      <Button
        fontSize={16}
        icon={
          <ClockCircleFilled
            style={{ color: baseColor.darkRed, fontSize: "14px" }}
          />
        }
        marginleft={16}
      >
        Timer
      </Button>
    </Popover>
  );
};

export default Upsert;
