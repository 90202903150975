import React, { useEffect } from "react";

import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { Formik, ErrorMessage } from "formik";
import { assign } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as Yup from "yup";

import { useScroll } from "../../../helpers/useScroll";
import { baseColor } from "../../../settings/foundations";
import {
  addCommentAction,
  updateCommentAction,
} from "../../../stores/actions/comment.action";
import { Button } from "../../atoms/Button";
import { Flex } from "../../atoms/Flexbox";
import { TextArea } from "../../atoms/Input";
import { Text } from "../../atoms/Typography";

const comentsSchema = Yup.object().shape({
  comment: Yup.string().required("Comment is a required field!"),
});

const CommentUpsert = ({ isOpen, handleCancel, upsertType }) => {
  const dispatch = useDispatch();
  const { scrollPosition } = useScroll();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });

  const { user } = useSelector(state => state?.authReducer);
  const { commentFormValues, isLoading } = useSelector(
    state => state?.commentsReducer
  );

  useEffect(() => {
    if (isLoading) {
      handleCancel();
    }
  }, [handleCancel, isLoading]);

  return (
    <Modal
      title={`${upsertType === "add" ? "Add" : "Edit"} Comment`}
      centered={!isTabletOrMobile}
      destroyOnClose
      visible={isOpen}
      footer={null}
      onCancel={handleCancel}
      style={{ top: 0 }}
      bodyStyle={{
        height: isTabletOrMobile ? "100vh" : "auto ",
        top: 0,
        left: 0,
      }}
    >
      <Formik
        validationSchema={comentsSchema}
        initialValues={commentFormValues}
        onSubmit={values => {
          if (upsertType === "add") {
            const computedValues = assign(values, { UserId: user.id });
            dispatch(addCommentAction(computedValues));
          } else {
            dispatch(updateCommentAction(values));
          }
          localStorage.setItem("scrollPosition", scrollPosition);
        }}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <Flex width={1} p={["8px", 16]} flexDirection="column">
              <TextArea
                size="large"
                rows={4}
                name="comment"
                placeholder="Add your Comment"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.comment}
              />
              <Text color={baseColor.red}>
                <ErrorMessage name="comment" />
              </Text>
            </Flex>
            <Flex
              width={1}
              p={["8px", 16]}
              position={isTabletOrMobile ? "absolute" : "static"}
              bottom="110px"
              left="-10px"
              justifyContent="flex-end"
            >
              <Button
                size="large"
                type="default"
                marginright={8}
                onClick={handleCancel}
                icon={<CloseOutlined />}
              >
                Cancel
              </Button>
              <Button
                size="large"
                htmlType="submit"
                type="primary"
                loading={props.isSubmitting}
                icon={<CheckOutlined />}
              >
                Save
              </Button>
            </Flex>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default CommentUpsert;
