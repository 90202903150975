import React from "react";

import { Link } from "react-router-dom";

import { MenuItem } from "./header.styles";

const MenuLink = ({ title, icon, path, className }) => {
  return (
    <MenuItem className={className}>
      <Link to={path}>
        {icon}
        <span>{title}</span>
      </Link>
    </MenuItem>
  );
};

export default MenuLink;
