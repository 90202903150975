import React from "react";

import { Modal } from "antd";
import { useMediaQuery } from "react-responsive";

import Form from "./form";

const Upsert = ({ isOpen, handleCancel, upsertType }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });

  return (
    <Modal
      title={`${upsertType === "add" ? "Add" : "Edit"} Employee`}
      centered={!isTabletOrMobile}
      destroyOnClose
      visible={isOpen}
      footer={null}
      onCancel={handleCancel}
      style={{ top: 0 }}
      bodyStyle={{
        height: isTabletOrMobile ? "100vh" : "auto ",
        top: 0,
        left: 0,
      }}
    >
      <Form handleCancel={handleCancel} upsertType={upsertType} />
    </Modal>
  );
};

export default Upsert;
