import update from "immutability-helper";

import { projectTypeConstants } from "../../constants";

const type = { name: "", status: true };

export default function projectTypeReducer(
  state = { types: [], type },
  action
) {
  switch (action.type) {
    case projectTypeConstants.GET_PROJECT_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case projectTypeConstants.GET_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        types: action.payload,
      };
    case projectTypeConstants.GET_PROJECT_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case projectTypeConstants.ADD_PROJECT_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case projectTypeConstants.ADD_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        types: [...state.types, action.payload],
        isLoading: false,
      };
    case projectTypeConstants.ADD_PROJECT_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case projectTypeConstants.UPDATE_PROJECT_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case projectTypeConstants.UPDATE_PROJECT_TYPE_SUCCESS:
      const itemIndex = state.types.findIndex(
        singleType => singleType.id === action.id
      );

      return {
        ...state,
        isLoading: false,
        types: update(state.types, {
          [itemIndex]: { $set: action.payload },
        }),
      };
    case projectTypeConstants.UPDATE_PROJECT_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case projectTypeConstants.SET_PROJECT_TYPE_DATA:
      return {
        ...state,
        type: action.payload,
        isLoading: false,
      };
    case projectTypeConstants.RESET_PROJECT_TYPE_DATA:
      return {
        ...state,
        type,
        isLoading: false,
      };
    default:
      return state;
  }
}
