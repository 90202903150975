import styled from "@emotion/styled";

import { Flex } from "../../atoms/Flexbox";

export const StyledPdfHeader = styled(Flex)`
  display: none;

  @media print {
    display: block;
  }
`;
export const StyledPdfBody = styled(Flex)`
  display: none;

  @media print {
    display: block;
  }
`;

export const StyledTotalHours = styled(Flex)`
  display: block;

  @media print {
    display: none;
  }
`;

export const StyledTimeSheetTable = styled(Flex)`
  display: block;
  @media print {
    width: 100%;
  }
  @page {
    size: landscape;
    margin: 10px;
  }
`;
export const StyledTableContent = styled(Flex)`
  display: block;

  @media print {
    max-width: 320px;
  }
`;

export const StyledActionButtons = styled(Flex)`
  display: block;

  @media print {
    display: none;
  }
`;
export const StyledComments = styled(Flex)`
  display: block;

  @media print {
    display: none;
  }
`;
export const StyledTableHeadWrapper = styled(Flex)`
  display: block;

  @media print {
    width: 122px;
  }
`;
