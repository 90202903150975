import { employeeSalaryConstants } from "../../constants";

export default function salaryReducer(
  state = {
    employees: [],
    totalTime: 0,
  },
  action
) {
  switch (action.type) {
    case employeeSalaryConstants.GET_EMPLOYEE_SALARY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case employeeSalaryConstants.GET_EMPLOYEE_SALARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employees: action.payload.employees,
        timeLogs: action.payload.timeLogs,
      };
    case employeeSalaryConstants.GET_EMPLOYEE_SALARY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
