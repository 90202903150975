/* eslint-disable react/destructuring-assignment */
import React from "react";

import { Tag } from "antd";
import { find, filter, chain } from "lodash";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { addTimes } from "../../../helpers/util";

export const useHelper = () => {
  const { projects } = useSelector(state => state?.projectReducer);

  const columns = [
    {
      title: "Name",
      key: "firstname",
      dataIndex: "firstname",
      width: "30%",
      render: (firstname, rowValue) =>
        `${rowValue.firstname} ${rowValue.lastname}`,
    },
    {
      title: "Projects",
      key: "loggedTimes",
      dataIndex: "loggedTimes",
      width: "30%",
      render: loggedTimes => {
        const computedTimeLog = chain(loggedTimes)
          .groupBy(loggedTime => loggedTime.ProjectId)
          .map((timeLogs, ProjectId) => ({ ProjectId }))
          .value();

        return (
          <>
            {computedTimeLog?.map(obj => {
              const computedProject = find(projects, project => {
                return project.id === obj.ProjectId;
              });

              return (
                <Tag color="default" key={obj.ProjectId}>
                  {computedProject?.name?.toUpperCase()}
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      responsive: ["xl"],
      title: "Working hours (approved / unapproved)",
      key: "loggedTimes",
      dataIndex: "loggedTimes",
      width: "30%",
      render: loggedTimes => {
        const approvedTimeLogs = filter(loggedTimes, timeLog => {
          return timeLog?.status === true;
        });
        const unApprovedTimeLogs = filter(loggedTimes, timeLog => {
          return timeLog?.status === false;
        });

        return `${addTimes(approvedTimeLogs)}/${addTimes(unApprovedTimeLogs)}`;
      },
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "id",
      render: id => {
        return <Link to={`/time-sheets?user=${id}`}>Details</Link>;
      },
    },
  ];

  return {
    columns,
  };
};
