import { message } from "antd";

import { projectCategoryConstants } from "../../constants";
import http from "../../services/httpService";

export const getProjectCategoryAction = () => {
  return dispatch => {
    dispatch({ type: projectCategoryConstants.GET_PROJECT_CATEGORY_REQUEST });
    http
      .get("/projectCategory/all")
      .then(res => {
        dispatch({
          type: projectCategoryConstants.GET_PROJECT_CATEGORY_SUCCESS,
          payload: res.data.projectCategories,
        });
      })
      .catch(() => {
        message.error("Unable to get project category data");
        dispatch({
          type: projectCategoryConstants.GET_PROJECT_CATEGORY_FAILURE,
        });
      });
  };
};

export function addProjectCategoryAction(payload) {
  return dispatch => {
    dispatch({ type: projectCategoryConstants.ADD_PROJECT_CATEGORY_REQUEST });
    http
      .post("/projectCategory/add", payload)
      .then(res => {
        message.success("Project category added successfully!");
        dispatch({
          type: projectCategoryConstants.ADD_PROJECT_CATEGORY_SUCCESS,
          payload: res.data.projectCategory,
        });
      })
      .catch(() => {
        message.error("Something went wrong. unable to add project category.");
        dispatch({
          type: projectCategoryConstants.ADD_PROJECT_CATEGORY_FAILURE,
        });
      });
  };
}

export function updateProjectCategoryAction(categoryId, payload) {
  return dispatch => {
    dispatch({
      type: projectCategoryConstants.UPDATE_PROJECT_CATEGORY_REQUEST,
    });
    http
      .patch(`/projectCategory/${categoryId}`, payload)
      .then(res => {
        message.success("Project category data updated successfully!");
        dispatch({
          type: projectCategoryConstants.UPDATE_PROJECT_CATEGORY_SUCCESS,
          id: categoryId,
          payload: res.data.category,
        });
      })
      .catch(() => {
        message.error(
          "Something went wrong. unable to update project category data"
        );
        dispatch({
          type: projectCategoryConstants.UPDATE_PROJECT_CATEGORY_FAILURE,
        });
      });
  };
}

export function setProjectCategoryAction(data) {
  return dispatch => {
    dispatch({
      type: projectCategoryConstants.SET_PROJECT_CATEGORY_DATA,
      payload: data,
    });
  };
}

export function resetProjectCategoryAction() {
  return dispatch => {
    dispatch({ type: projectCategoryConstants.RESET_PROJECT_CATEGORY_DATA });
  };
}
