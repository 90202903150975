import React from "react";

import { Area } from "@ant-design/charts";
import { reduce, times, pick, map, unionBy, orderBy, round } from "lodash";
import moment from "moment";

import { baseColor } from "../../../settings/foundations";
import { Flex, Box } from "../../atoms/Flexbox";
import { Title, Text } from "../../atoms/Typography";

const Stats = ({
  timeLogs,
  totalHours,
  totalApprovedHours,
  totalUnApprovedHours,
  averageHoursPerEmployee,
}) => {
  const daysInMonth = [];
  const startOfMonth = moment().startOf("month").format("DD MMM");
  const endOfMonth = moment().endOf("month").format("DD MMM YYYY");
  const monthDate = moment().startOf("month");

  // TODO: have to move this logic in BE
  // Adding Time logs -> Those who have same date
  const AddTimeLogs = reduce(
    timeLogs,
    (timeLogsArray, singleTimeLogObject) => {
      const existObj = timeLogsArray.find(
        item => item.date === singleTimeLogObject.date
      );
      if (existObj) {
        existObj.durationHours += singleTimeLogObject.durationHours;
        return timeLogsArray;
      }

      timeLogsArray.push(singleTimeLogObject);
      return timeLogsArray;
    },
    []
  );

  // Pick date and timeLogged properties from time logs
  const computedTimeLogs = map(AddTimeLogs, timeLog => {
    return pick(timeLog, ["date", "durationHours"]);
  });

  // Creating day of months array
  times(monthDate.daysInMonth(), () => {
    daysInMonth.push({
      date: monthDate.format("YYYY-MM-DD"),
      durationHours: 0,
    });
    monthDate.add(1, "day");
  });

  // Merging day of months array and timelogs array
  const mergedArrays = unionBy(computedTimeLogs, daysInMonth, "date");

  // Sorting merged array on the basis of date
  const sortedArray = orderBy(
    mergedArrays,
    obj => {
      return moment(obj.date).format("YYYY-MM-DD");
    },
    ["asc"]
  );

  const config = {
    data: sortedArray,
    xField: "date",
    yField: "durationHours",
    areaStyle: function areaStyle() {
      return { fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff" };
    },
  };

  return (
    <Flex
      width={1}
      mt={40}
      mx="auto"
      border="1px solid #DFE0EB"
      borderRadius="8px"
      flexDirection={["column", "row"]}
    >
      <Flex
        width={[1, 5 / 7]}
        flexDirection="column"
        justifyContent="center"
        borderRight="1px solid #DFE0EB"
      >
        <Box m={[15, 10, 32]}>
          <Title fontSize={24} color={baseColor.head}>
            All projects
          </Title>
          <Text fontSize={14} color={baseColor.lightText}>
            {startOfMonth} - {endOfMonth}
          </Text>
        </Box>
        <Box m={[15, 10, 32]}>
          <Area {...config} />
        </Box>
      </Flex>
      <Flex width={[1, 2 / 7]} flexDirection="column">
        <Box
          width={1}
          py={32}
          textAlign="center"
          borderBottom="1px solid #DFE0EB"
        >
          <Text fontSize={14} color={baseColor.lightText}>
            Total hours
          </Text>
          <Title fontSize={24} margin="6" color={baseColor.head}>
            {totalHours === "00:00" ? 0 : totalHours}h
          </Title>
        </Box>
        <Box
          width={1}
          py={32}
          textAlign="center"
          borderBottom="1px solid #DFE0EB"
        >
          <Text fontSize={14} color={baseColor.lightText}>
            Approved
          </Text>
          <Title fontSize={24} margin="6" color={baseColor.head}>
            {totalApprovedHours === "00:00" ? 0 : totalApprovedHours}h
          </Title>
        </Box>
        <Box
          width={1}
          py={32}
          textAlign="center"
          borderBottom="1px solid #DFE0EB"
        >
          <Text fontSize={14} color={baseColor.lightText}>
            Unapproved
          </Text>
          <Title fontSize={24} margin="6" color={baseColor.head}>
            {totalUnApprovedHours === "00:00" ? 0 : totalUnApprovedHours}h
          </Title>
        </Box>
        <Box
          width={1}
          py={32}
          textAlign="center"
          borderBottom="1px solid #DFE0EB"
        >
          <Text fontSize={14} color={baseColor.lightText}>
            Average hours per employee
          </Text>
          <Title fontSize={24} margin="6" color={baseColor.head}>
            {round(averageHoursPerEmployee)}h
          </Title>
        </Box>
        <Box width={1} py={32} textAlign="center">
          <Text fontSize={14} color={baseColor.lightText}>
            Top project
          </Text>
          <Title fontSize={24} margin="6" color={baseColor.head}>
            Project 1
          </Title>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Stats;
