import styled from "@emotion/styled";
import { Button as AntButton } from "antd";

export const Button = styled(AntButton)`
  font-family: "Roboto";
  font-size: ${props =>
    props.fontSize ? `${props.fontSize}px !important` : ""};
  font-weight: ${props =>
    props.fontWeight ? `${props.fontWeight}px !important` : ""};
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px !important` : ""};
  color: ${props => (props.color ? `${props.color} !important` : "")};
  opacity: ${props => (props.opacity ? `${props.opacity} !important` : "")};
  background-color: ${props =>
    props.backgroundColor ? `${props.backgroundColor} !important` : ""};
  border-color: ${props =>
    props.borderColor ? `${props.borderColor} !important` : ""};
  height: ${props => (props.height ? `${props.height}px !important` : "100%")};
  padding: ${props => (props.padding ? `${props.padding}px !important` : "")};
  padding-bottom: ${props =>
    props.paddingbottom ? `${props.paddingbottom}px !important` : ""};
  padding-top: ${props =>
    props.paddingtop ? `${props.paddingtop}px !important` : ""};
  padding-left: ${props =>
    props.paddingleft ? `${props.paddingleft}px !important` : ""};
  padding-right: ${props =>
    props.paddingright ? `${props.paddingright}px !important` : ""};
  margin: ${props => (props.margin ? `${props.margin}px !important` : "")};
  margin-bottom: ${props =>
    props.marginbottom ? `${props.marginbottom}px !important` : ""};
  margin-top: ${props =>
    props.margintop ? `${props.margintop}px !important` : ""};
  margin-left: ${props =>
    props.marginleft ? `${props.marginleft}px !important` : ""};
  margin-right: ${props =>
    props.marginright ? `${props.marginright}px !important` : ""};
`;
