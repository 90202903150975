import update from "immutability-helper";

import { employeeConstants } from "../../constants";

const employee = {
  firstname: "",
  lastname: "",
  email: "",
  dob: null,
  status: false,
  role: "",
};

export default function employeeReducer(
  state = {
    employees: [],
    employee,
  },
  action
) {
  switch (action.type) {
    case employeeConstants.GET_EMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case employeeConstants.GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employees: action.payload,
      };
    case employeeConstants.GET_EMPLOYEE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case employeeConstants.ADD_EMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case employeeConstants.ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employees: [...state.employees, action.payload],
        isLoading: false,
      };
    case employeeConstants.ADD_EMPLOYEE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case employeeConstants.UPDATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case employeeConstants.UPDATE_EMPLOYEE_SUCCESS:
      const itemIndex = state.employees.findIndex(emp => emp.id === action.id);
      return {
        ...state,
        isLoading: false,
        employees: update(state.employees, {
          [itemIndex]: { $set: action.payload },
        }),
      };
    case employeeConstants.UPDATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case employeeConstants.SET_EMPLOYEE_DATA:
      return {
        ...state,
        employee: action.payload,
        isLoading: false,
      };
    case employeeConstants.RESET_EMPLOYEE_DATA:
      return {
        ...state,
        employee,
        isLoading: false,
      };
    default:
      return state;
  }
}
