import React from "react";

import { Table } from "antd";
import { useSelector } from "react-redux";

import { Flex } from "../../atoms/Flexbox";
import { useHelper } from "./useHelper";

const SalariesTable = ({ handleSelectedRows }) => {
  const helper = useHelper();
  const { employees, isLoading } = useSelector(state => state?.salaryReducer);

  return (
    <Flex width={1} flexDirection="column" mt={24} px={[12, 10, 0]}>
      <Table
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: selectedRowKeys => {
            handleSelectedRows(selectedRowKeys, employees);
          },
        }}
        loading={isLoading}
        pagination={false}
        columns={helper.columns}
        dataSource={employees}
        onChange={selectedRows => {
          handleSelectedRows(selectedRows);
        }}
      />
    </Flex>
  );
};

export default SalariesTable;
