import { message } from "antd";

import { userConstants } from "../../constants";
import http from "../../services/httpService";

export const forgotPasswordAction = email => {
  return dispatch => {
    dispatch({ type: userConstants.RESET_REQUEST });

    http
      .post("/users/resetPassword", {
        email,
      })
      .then(res => {
        message.success(res.data.message);
        dispatch({ type: userConstants.RESET_SUCCESS });
      })
      .catch(() => {
        message.error("Something went wrong");
        dispatch({ type: userConstants.RESET_FAILURE });
      });
  };
};
