/* eslint-disable react/destructuring-assignment */
import React from "react";

// import { PlusOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { useSelector } from "react-redux";

import { baseColor } from "../../../../settings/foundations";
// import { Button } from "../../../atoms/Button";
import { Flex } from "../../../atoms/Flexbox";
import { Title } from "../../../atoms/Typography";
import Upsert from "./upsert";
import { useHelper } from "./useHelper";

const Types = () => {
  const helper = useHelper();

  const types = useSelector(state => state?.projectTypeReducer?.types);
  const isLoading = useSelector(state => state?.projectTypeReducer?.isLoading);

  return (
    <>
      <Flex flexDirection="column" width={1} mt={80} id="types">
        <Flex alignItems="center" justifyContent="space-between" mb={16}>
          <Title fontSize={24} color={baseColor.gray}>
            Types
          </Title>
        </Flex>
        <Flex width={1} flexDirection="column">
          <Table
            rowKey="id"
            pagination={false}
            loading={isLoading}
            columns={helper.columns}
            dataSource={types}
          />
        </Flex>
      </Flex>
      <Upsert
        isOpen={helper.isOpen}
        handleCancel={helper.handleCancel}
        upsertType={helper.upsertType}
      />
    </>
  );
};

export default Types;
