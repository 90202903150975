import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Login from "../components/organisms/Login";
import useToken from "../helpers/useToken";
import AppLayout from "../Layout";

const AuthPage = () => {
  const history = useHistory();
  const { token } = useToken();

  const { user } = useSelector(state => state?.authReducer);

  useEffect(() => {
    if (token) {
      if (
        user?.role === "SUPER ADMIN" ||
        user?.role === "ADMIN" ||
        user?.role === "MANAGER"
      ) {
        history.push("/statistics");
      } else {
        history.push(`/time-sheets`);
      }
    }
  }, [history, token, user?.role]);

  return (
    <AppLayout theme="absolute">
      <Login />
    </AppLayout>
  );
};

export default AuthPage;
