import React from "react";

import { Modal } from "antd";
import { assign } from "lodash";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { addTimeLogAction } from "../../../stores/actions/timeTracking.action";
import Form from "./form";

const AddTimelogUpsert = ({ isOpen, handleCancel, userId }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
  const dispatch = useDispatch();

  const onSubmit = (values, resetForm) => {
    const computedValues = assign(values, { UserId: userId });
    dispatch(addTimeLogAction(computedValues, resetForm));
  };

  return (
    <Modal
      title="Add Time"
      centered={!isTabletOrMobile}
      destroyOnClose
      visible={isOpen}
      footer={null}
      onCancel={handleCancel}
      style={{
        top: 0,
        overflowY: "auto",
        minHeight: isTabletOrMobile ? "100vh" : "auto ",
      }}
      bodyStyle={{
        top: 0,
        left: 0,
        overflowY: "auto",
      }}
    >
      <Form
        handleCancel={handleCancel}
        onSubmit={onSubmit}
        initialValues={{
          date: moment(),
          startTime: "",
          endTime: "",
          breakTime: moment("01:00", "HH:mm").format("hh:mm:ss a"),
          ProjectId: "",
          ProjectCategoryId: "",
          description: "",
        }}
      />
    </Modal>
  );
};

export default AddTimelogUpsert;
