/* eslint-disable react/jsx-boolean-value */
import React, { useEffect } from "react";

import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Formik, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { baseColor } from "../../../../settings/foundations";
import {
  addProjectTypeAction,
  updateProjectTypeAction,
} from "../../../../stores/actions/projectType.action";
import { Button } from "../../../atoms/Button";
import { Flex, Box } from "../../../atoms/Flexbox";
import { Input } from "../../../atoms/Input";
import { Select, Option } from "../../../atoms/Select";
import { Text } from "../../../atoms/Typography";
import { useHelper } from "./useHelper";

const TypesForm = ({ handleCancel, upsertType }) => {
  const helper = useHelper();
  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });

  const { isLoading } = useSelector(state => state?.projectTypeReducer);
  const { type } = useSelector(state => state?.projectTypeReducer);

  useEffect(() => {
    if (isLoading) {
      handleCancel();
    }
  }, [handleCancel, isLoading]);

  return (
    <Formik
      validationSchema={helper.typesSchema}
      initialValues={type}
      onSubmit={values => {
        if (upsertType === "add") {
          dispatch(addProjectTypeAction(values));
        } else if (upsertType === "edit") {
          dispatch(updateProjectTypeAction(type.id, values));
        }
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Flex width={1} flexDirection={["column", "row"]}>
            <Box width={[1, 1 / 2]} p={["8px", 16]}>
              <Input
                name="name"
                type="text"
                placeholder="Type Name"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.name}
              />
              <Text color={baseColor.red}>
                <ErrorMessage name="name" />
              </Text>
            </Box>
            <Box width={[1, 1 / 2]} p={["8px", 16]}>
              <Select
                placeholder="Status"
                value={props.values.status}
                onChange={value => {
                  props.setFieldValue("status", value);
                }}
                onBlur={value => props.setFieldTouched("status", value)}
              >
                <Option value={true}>Active</Option>
                <Option value={false}>Disable</Option>
              </Select>
              <Text color={baseColor.red}>
                <ErrorMessage name="status" />
              </Text>
            </Box>
          </Flex>
          <Flex
            width={1}
            p={16}
            position={isTabletOrMobile ? "absolute" : "static"}
            bottom="110px"
            left="-10px"
            justifyContent="flex-end"
          >
            <Button
              type="default"
              icon={<CloseOutlined />}
              marginright={8}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoading}
              icon={<CheckOutlined />}
            >
              Save
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default TypesForm;
