import React from "react";

import { Route, Redirect } from "react-router-dom";

import useToken from "../helpers/useToken";

const PrivateRoute = ({ component, path, show, exact }) => {
  const { token } = useToken();

  return token && show ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to="/auth" />
  );
};
export default PrivateRoute;
