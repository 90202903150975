import React, { useState, useCallback } from "react";

import { Badge } from "antd";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as Yup from "yup";

import {
  setProjectTypeAction,
  resetProjectTypeAction,
} from "../../../../stores/actions/projectType.action";

export const useHelper = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [upsertType, setUpsertType] = useState("add");

  const handleCancel = () => {
    setIsOpen(false);
    dispatch(resetProjectTypeAction());
  };

  const handleOpen = useCallback(
    (type, rowValues) => {
      setIsOpen(true);
      setUpsertType(type);
      if (type === "edit") {
        dispatch(setProjectTypeAction(rowValues));
      }
    },
    [dispatch]
  );

  const columns = [
    {
      width: isMobile ? "40%" : "45%",
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      width: isMobile ? "40%" : "45%",
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: status => (
        <Badge
          status={status ? "success" : "error"}
          text={status ? "Active" : "Disabled"}
        />
      ),
    },
  ];

  const typesSchema = Yup.object().shape({
    name: Yup.string().required("Type name is a required field!"),
    status: Yup.bool().required("Status is a required field!"),
  });

  return {
    isOpen,
    columns,
    upsertType,
    handleOpen,
    typesSchema,
    handleCancel,
  };
};
