import React from "react";

import { CaretDownOutlined } from "@ant-design/icons";
import { Menu, Dropdown } from "antd";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { baseColor } from "../../../settings/foundations";
import { logout } from "../../../stores/actions/auth.actions";
import { Avatar } from "../../atoms/Avatar";
import { Button } from "../../atoms/Button";
import { Flex } from "../../atoms/Flexbox";

const User = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1200 });

  const user = useSelector(state => state?.authReducer?.user);

  const menu = (
    <Menu>
      <Menu.Item onClick={logout}>Sign Out</Menu.Item>
    </Menu>
  );

  return isTabletOrMobile ? (
    <Flex width={1} alignItems="center" ml={3}>
      <Dropdown overlay={menu} placement="bottomCenter">
        <Avatar bg={baseColor.lightRed} color={baseColor.red}>
          {user?.firstname.charAt(0).toUpperCase() +
            user?.lastname.charAt(0).toUpperCase()}
        </Avatar>
      </Dropdown>
    </Flex>
  ) : (
    <Flex width={1} alignItems="center" ml={4}>
      <Avatar bg={baseColor.lightRed} color={baseColor.red}>
        {user?.firstname.charAt(0).toUpperCase() +
          user?.lastname.charAt(0).toUpperCase()}
      </Avatar>

      <Dropdown overlay={menu} placement="bottomCenter">
        <Button type="link" fontSize={16} color={baseColor.light}>
          {`${user?.firstname} ${user?.lastname}`} <CaretDownOutlined />
        </Button>
      </Dropdown>
    </Flex>
  );
};

export default User;
