import React from "react";

import { GlobalOutlined } from "@ant-design/icons";
import { Menu, Dropdown } from "antd";

import { baseColor } from "../../../settings/foundations";
import { Button } from "../../atoms/Button";

const Language = () => {
  const menu = (
    <Menu>
      <Menu.Item>Dutch</Menu.Item>
      <Menu.Item>Arabic</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="topCenter">
      <Button
        type="link"
        fontSize={16}
        opacity="0.85"
        color={baseColor.black}
        icon={<GlobalOutlined />}
      >
        English
      </Button>
    </Dropdown>
  );
};

export default Language;
