import React, { useEffect, useRef, useState } from "react";

import { Spin, Empty } from "antd";
import { isEmpty, find, isNull } from "lodash";
import moment from "moment";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

import { baseColor } from "../../../settings/foundations";
import { getEmployeesAction } from "../../../stores/actions/employee.action";
import { getProjectsAction } from "../../../stores/actions/project.actions";
import { getProjectCategoryAction } from "../../../stores/actions/projectCategory.action";
import { getEmployeeSalaryAction } from "../../../stores/actions/salary.action";
import { getEmployeeTimeSheetsAction } from "../../../stores/actions/timeSheet.action";
import { Flex } from "../../atoms/Flexbox";
import Filter from "./filter";
import PdfHeader from "./pdfHeader";
import TimeSheet from "./timeSheet";
import "./styles.css";
import { StyledTimeSheetTable } from "./timeSheets.styles";

const Timesheets = () => {
  const pdfRef = useRef();
  const dispatch = useDispatch();

  const [project, setProject] = useState(null);
  const [projectDate, setProjectDate] = useState(null);

  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

  const { employees, updated, isLoading } = useSelector(
    state => state?.timeSheetReducer
  );
  const { added } = useSelector(state => state?.commentsReducer);
  const { submitting } = useSelector(state => state?.timeTrackingReducer);
  const { user } = useSelector(state => state?.authReducer);
  const { timeLogs } = useSelector(state => state?.salaryReducer);
  // const { timeSheet } = useSelector(state => state?.timeSheetReducer);
  const { projects } = useSelector(state => state?.projectReducer);

  const parsed = queryString.parse(window.location.search);

  const isAdmin =
    user?.role === "SUPER ADMIN" ||
    user?.role === "ADMIN" ||
    user?.role === "MANAGER";

  const isCollaborator = user?.role === "COLLABORATOR";

  const handleSubmit = (
    selectedProject,
    selectedCategory,
    selectedEmployee,
    startDate,
    endDate
  ) => {
    const computedProject = find(projects, elem => {
      return elem.id === selectedProject;
    });

    // TODO: Need to remove this apporach
    setProject(computedProject);
    dispatch(
      getEmployeeTimeSheetsAction(
        selectedProject,
        selectedCategory,
        selectedEmployee,
        startDate,
        endDate
      )
    );
    dispatch(getEmployeeSalaryAction(selectedProject, startDate, endDate));
  };

  useEffect(() => {
    const filterItems = JSON.parse(sessionStorage.getItem("filterValues"));

    const collaboratorValue = isCollaborator
      ? user.id
      : parsed.user
      ? parsed.user
      : null;

    dispatch(getEmployeesAction());
    dispatch(getProjectsAction());
    dispatch(getProjectCategoryAction());
    dispatch(
      getEmployeeSalaryAction(
        null,
        !isNull(filterItems) ? filterItems.startDate : startOfMonth,
        !isNull(filterItems) ? filterItems.endDate : endOfMonth
      )
    );
    dispatch(
      getEmployeeTimeSheetsAction(
        !isNull(filterItems) ? filterItems.projectId : null,
        isCollaborator
          ? collaboratorValue
          : !isNull(filterItems)
          ? filterItems.userId
          : null,
        !isNull(filterItems) ? filterItems.projectCategoryId : null,
        !isNull(filterItems) ? filterItems.startDate : startOfMonth,
        !isNull(filterItems) ? filterItems.endDate : endOfMonth
      )
    );
  }, [
    dispatch,
    updated,
    added,
    parsed.user,
    submitting,
    isCollaborator,
    user.id,
    startOfMonth,
    endOfMonth,
  ]);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });
  return (
    <>
      <Flex
        bg={baseColor.white}
        maxWidth={["100%", "100%", "1220px", "1440px"]}
        px={[0, "20px"]}
        flexDirection="column"
        mx="auto"
        mb={[90, 20]}
        height="100%"
      >
        <Filter
          handlePrint={handlePrint}
          isAdmin={isAdmin}
          onHandleSubmit={handleSubmit}
          setProjectDate={setProjectDate}
          // setDisplay={setDisplay}
        />
        {isLoading && (
          <Flex
            width={1}
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
          >
            <Spin size="large" />
          </Flex>
        )}
        <div ref={pdfRef}>
          <PdfHeader
            project={project}
            projectDate={projectDate}
            timeLogs={timeLogs}
          />

          {!isEmpty(employees) && !isLoading
            ? employees.map(employee => (
                <StyledTimeSheetTable>
                  <TimeSheet
                    isAdmin={isAdmin}
                    isCollaborator={isCollaborator}
                    employee={employee}
                  />
                </StyledTimeSheetTable>
              ))
            : !isLoading && (
                <Flex
                  width={1}
                  justifyContent="center"
                  alignItems="center"
                  minHeight="50vh"
                >
                  <Empty />
                </Flex>
              )}
        </div>
      </Flex>
    </>
  );
};

export default Timesheets;
