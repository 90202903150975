import { message } from "antd";

import { timeTrackingConstants } from "../../constants";
import http from "../../services/httpService";

export function addTimeLogAction(payload, resetForm) {
  return dispatch => {
    dispatch({ type: timeTrackingConstants.ADD_TIME_REQUEST });
    http
      .post("/loggedTime/add", payload)
      .then(() => {
        message.success("Time added successfully");
        dispatch({
          type: timeTrackingConstants.ADD_TIME_SUCCESS,
        });
        resetForm();
      })
      .catch(() => {
        message.error("Something went wrong. Unable to add time.");
        dispatch({ type: timeTrackingConstants.ADD_TIME_FAILURE });
      });
  };
}
