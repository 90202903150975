import { employeeTimesheetConstants } from "../../constants";

const timeSheet = {
  project: null,
  category: null,
  date: null,
  startTime: null,
  endTime: true,
  description: "",
};

export default function timeSheetReducer(
  state = {
    employees: [],
    timeSheet,
  },
  action
) {
  switch (action.type) {
    case employeeTimesheetConstants.GET_EMPLOYEE_TIMESHEET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case employeeTimesheetConstants.GET_EMPLOYEE_TIMESHEET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employees: action.payload.employees,
      };
    case employeeTimesheetConstants.GET_EMPLOYEE_TIMESHEET_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case employeeTimesheetConstants.UPDATE_EMPLOYEE_TIME_STATUS_REQUEST:
      return {
        ...state,
        updated: false,
      };
    case employeeTimesheetConstants.UPDATE_EMPLOYEE_TIME_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: true,
        updated: true,
      };
    case employeeTimesheetConstants.UPDATE_EMPLOYEE_TIME_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        updated: true,
      };
    case employeeTimesheetConstants.SET_TIMESHEET_DATA:
      return {
        ...state,
        timeSheet: action.payload,
        isLoading: false,
      };
    case employeeTimesheetConstants.RESET_TIMESHEET_DATA:
      return {
        ...state,
        timeSheet,
        isLoading: false,
      };
    case employeeTimesheetConstants.UPDATE_EMPLOYEE_TIME_REQUEST:
      return {
        ...state,
        updated: false,
        isLoading: false,
      };

    case employeeTimesheetConstants.UPDATE_EMPLOYEE_TIME_SUCCESS:
      return {
        ...state,
        updated: true,
        isLoading: true,
      };

    case employeeTimesheetConstants.UPDATE_EMPLOYEE_TIME_FAILURE:
      return {
        ...state,
        updated: true,
        isLoading: false,
      };

    case employeeTimesheetConstants.DELETE_EMPLOYEE_TIME_REQUEST:
      return {
        ...state,
        updated: false,
      };

    case employeeTimesheetConstants.DELETE_EMPLOYEE_TIME_SUCCESS:
      const dellEmployees = state.employees.map(emp => {
        const remainingData = emp.loggedTimes.filter(
          reminder => reminder.id !== action.payload.id
        );
        return {
          ...emp,
          loggedTimes: remainingData,
        };
      });

      return {
        ...state,
        employees: dellEmployees,
        isLoading: false,
        // updated: true,
      };
    case employeeTimesheetConstants.DELETE_EMPLOYEE_TIME_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case employeeTimesheetConstants.SET_CONFIRM_DELETE:
      return {
        ...state,
        selectedId: action.payload,
      };
    case employeeTimesheetConstants.RESET_CONFIRM_DELETE:
      return {
        ...state,
        selectedId: "",
      };
    default:
      return state;
  }
}
