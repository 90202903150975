import React from "react";

import { Result } from "antd";
import { Link } from "react-router-dom";

import { Button } from "../components/atoms/Button";
import { Flex } from "../components/atoms/Flexbox";
import AppLayout from "../Layout";

const NotFoundPage = () => {
  return (
    <AppLayout theme="absolute">
      <Flex width={1} mx="auto" my="8%" justifyContent="center">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Link to="/">
              <Button>Home</Button>
            </Link>
          }
        />
      </Flex>
    </AppLayout>
  );
};

export default NotFoundPage;
