import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import Salaries from "../components/organisms/Salaries";
import AppLayout from "../Layout";
import { checkAuth } from "../stores/actions/auth.actions";

const SalariesPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  return (
    <AppLayout theme="primary" isprimary>
      <Salaries />
    </AppLayout>
  );
};

export default SalariesPage;
