import { message } from "antd";

import { projectTypeConstants } from "../../constants";
import http from "../../services/httpService";

export function getProjectTypeAction() {
  return dispatch => {
    dispatch({ type: projectTypeConstants.GET_PROJECT_TYPE_REQUEST });
    http
      .get("/projectType/all")
      .then(res => {
        dispatch({
          type: projectTypeConstants.GET_PROJECT_TYPE_SUCCESS,
          payload: res.data.projectTypes,
        });
      })
      .catch(() => {
        message.error("Unable to get project type data");
        dispatch({
          type: projectTypeConstants.GET_PROJECT_TYPE_FAILURE,
        });
      });
  };
}

export function addProjectTypeAction(payload) {
  return dispatch => {
    dispatch({ type: projectTypeConstants.ADD_PROJECT_TYPE_REQUEST });
    http
      .post("/projectType/add", payload)
      .then(res => {
        message.success("Project type added successfully!");
        dispatch({
          type: projectTypeConstants.ADD_PROJECT_TYPE_SUCCESS,
          payload: res.data.projectType,
        });
      })
      .catch(() => {
        message.error("Something went wrong. unable to add project type.");
        dispatch({ type: projectTypeConstants.ADD_PROJECT_TYPE_FAILURE });
      });
  };
}

export function updateProjectTypeAction(typeId, payload) {
  return dispatch => {
    dispatch({
      type: projectTypeConstants.UPDATE_PROJECT_TYPE_REQUEST,
    });
    http
      .patch(`/projectType/${typeId}`, payload)
      .then(res => {
        message.success("Project type data updated successfully!");
        dispatch({
          type: projectTypeConstants.UPDATE_PROJECT_TYPE_SUCCESS,
          id: typeId,
          payload: res.data.type,
        });
      })
      .catch(() => {
        message.error(
          "Something went wrong. unable to update project type data"
        );
        dispatch({
          type: projectTypeConstants.UPDATE_PROJECT_TYPE_FAILURE,
        });
      });
  };
}

export function setProjectTypeAction(data) {
  return dispatch => {
    dispatch({
      type: projectTypeConstants.SET_PROJECT_TYPE_DATA,
      payload: data,
    });
  };
}

export function resetProjectTypeAction() {
  return dispatch => {
    dispatch({ type: projectTypeConstants.RESET_PROJECT_TYPE_DATA });
  };
}
