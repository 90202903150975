import { statisticsConstants } from "../../constants";

export default function statisticsReducer(
  state = {
    employees: [],
    timeLogs: [],
    totalHours: 0,
    totalApprovedHours: 0,
    totalUnApprovedHours: 0,
    averageHoursPerEmployee: 0,
  },
  action
) {
  switch (action.type) {
    case statisticsConstants.GET_STATISTICS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case statisticsConstants.GET_STATISTICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employees: action.payload.employees,
        timeLogs: action.payload.timeLogs,
        totalHours: action.payload.totalHours,
        totalApprovedHours: action.payload.totalApprovedHours,
        totalUnApprovedHours: action.payload.totalUnApprovedHours,
        averageHoursPerEmployee: action.payload.averageHoursPerEmployee,
        usersCount: action.payload.usersCount,
        approvedTimeLogs: action.payload.approvedTimeLogs,
        unApprovedTimeLogs: action.payload.unApprovedTimeLogs,
      };
    case statisticsConstants.GET_STATISTICS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
