import { message } from "antd";

import { projectConstants } from "../../constants";
import http from "../../services/httpService";

export const getProjectsAction = () => {
  return dispatch => {
    dispatch({ type: projectConstants.GET_PROJECT_REQUEST });
    http
      .get("/projects/all")
      .then(res => {
        dispatch({
          type: projectConstants.GET_PROJECT_SUCCESS,
          payload: res.data.projects,
        });
      })
      .catch(() => {
        message.error("Unable to get projects data");
        dispatch({ type: projectConstants.GET_PROJECT_FAILURE });
      });
  };
};

export function addProjectAction(payload) {
  return dispatch => {
    dispatch({ type: projectConstants.ADD_PROJECT_REQUEST });
    http
      .post("/projects/add", payload)
      .then(res => {
        message.success("Project added successfully!");
        dispatch({
          type: projectConstants.ADD_PROJECT_SUCCESS,
          payload: res.data.project,
        });
      })
      .catch(() => {
        message.error("Something went wrong. unable to add project.");
        dispatch({
          type: projectConstants.ADD_PROJECT_FAILURE,
        });
      });
  };
}

export function updateProjectAction(projectId, payload) {
  return dispatch => {
    dispatch({ type: projectConstants.UPDATE_PROJECT_REQUEST });
    http
      .patch(`/projects/${projectId}`, payload)
      .then(res => {
        message.success("Project data updated successfully!");
        dispatch({
          type: projectConstants.UPDATE_PROJECT_SUCCESS,
          id: projectId,
          payload: res.data.project,
        });
      })
      .catch(() => {
        message.error("Something went wrong. unable to update project data");
        dispatch({
          type: projectConstants.UPDATE_PROJECT_FAILURE,
        });
      });
  };
}

export function setProjectAction(data) {
  return dispatch => {
    dispatch({ type: projectConstants.SET_PROJECT_DATA, payload: data });
  };
}

export function resetProjectAction() {
  return dispatch => {
    dispatch({ type: projectConstants.RESET_PROJECT_DATA });
  };
}
