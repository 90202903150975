import { message } from "antd";

import { rolesConstants } from "../../constants";
import http from "../../services/httpService";

export function getRolesAction() {
  return dispatch => {
    dispatch({ type: rolesConstants.GET_ROLES_REQUEST });
    http
      .get("/roles/all")
      .then(res => {
        dispatch({
          type: rolesConstants.GET_ROLES_SUCCESS,
          payload: res.data.roles,
        });
      })
      .catch(() => {
        message.error("Unable to get roles data");
        dispatch({
          type: rolesConstants.GET_ROLES_FAILURE,
        });
      });
  };
}
