import React, { useState, useCallback } from "react";

import { EditFilled } from "@ant-design/icons";
import { Badge } from "antd";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as Yup from "yup";

import { baseColor } from "../../../../settings/foundations";
import {
  setProjectCategoryAction,
  resetProjectCategoryAction,
} from "../../../../stores/actions/projectCategory.action";
import { Button } from "../../../atoms/Button";

export const useHelper = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [upsertType, setUpsertType] = useState("add");

  const handleCancel = () => {
    setIsOpen(false);
    dispatch(resetProjectCategoryAction());
  };

  const handleOpen = useCallback(
    (type, rowValues) => {
      setIsOpen(true);
      setUpsertType(type);
      if (type === "edit") {
        dispatch(setProjectCategoryAction(rowValues));
      }
    },
    [dispatch]
  );

  const columns = [
    {
      width: isMobile ? "40%" : "30%",
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      width: isMobile ? "40%" : "30%",
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: status => (
        <Badge
          status={status ? "success" : "error"}
          text={status ? "Active" : "Disabled"}
        />
      ),
    },
    {
      responsive: ["xl"],
      width: isMobile ? "0%" : "30%",
      title: "Type",
      key: "type",
      dataIndex: "type",
    },
    {
      width: isMobile ? "20%" : "10%",
      title: "Action",
      key: "action",
      render: (value, rowValues) => (
        <Button
          onClick={() => handleOpen("edit", rowValues)}
          icon={<EditFilled style={{ color: baseColor.primary }} />}
        />
      ),
    },
  ];

  const categoriesSchema = Yup.object().shape({
    name: Yup.string().required("Category name is a required field!"),
    status: Yup.bool().required("Status is a required field!"),
    type: Yup.string().required("Type is a required field!"),
  });

  return {
    isOpen,
    columns,
    handleOpen,
    upsertType,
    handleCancel,
    categoriesSchema,
  };
};
