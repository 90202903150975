import React, { useEffect } from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { addTimes } from "../../../helpers/util";
import { baseColor } from "../../../settings/foundations";
import { getEmployeesAction } from "../../../stores/actions/employee.action";
import { getProjectsAction } from "../../../stores/actions/project.actions";
import { getProjectCategoryAction } from "../../../stores/actions/projectCategory.action";
import { getStatisticsAction } from "../../../stores/actions/statistics.action";
import { Flex } from "../../atoms/Flexbox";
import Filter from "./filter";
import Stats from "./stats";
import StatsTable from "./table";

const StatisticsPanel = () => {
  const dispatch = useDispatch();
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

  const {
    employees,
    timeLogs,
    usersCount,
    approvedTimeLogs,
    unApprovedTimeLogs,
  } = useSelector(state => state?.statisticsReducer);
  const { submitting } = useSelector(state => state?.timeTrackingReducer);

  const handleSubmit = (
    selectedProject,
    selectedCategory,
    selectedEmployee,
    startDate,
    endDate
  ) => {
    dispatch(
      getStatisticsAction(
        selectedProject,
        selectedCategory,
        selectedEmployee,
        startDate,
        endDate
      )
    );
  };

  useEffect(() => {
    dispatch(getEmployeesAction());
    dispatch(getProjectsAction());
    dispatch(getProjectCategoryAction());
    dispatch(getStatisticsAction(null, null, null, startOfMonth, endOfMonth));
  }, [dispatch, endOfMonth, startOfMonth, submitting]);

  return (
    <Flex
      bg={baseColor.white}
      maxWidth={["100%", "100%", "1220px", "1440px"]}
      px={[0, "20px"]}
      mx="auto"
      flexDirection="column"
      mb={[90, 20]}
    >
      <Filter onHandleSubmit={handleSubmit} />
      <Stats
        timeLogs={timeLogs}
        totalHours={addTimes(timeLogs)}
        totalApprovedHours={addTimes(approvedTimeLogs)}
        totalUnApprovedHours={addTimes(unApprovedTimeLogs)}
        averageHoursPerEmployee={
          parseInt(addTimes(timeLogs).split(":")[0], 10) / usersCount
        }
      />
      <StatsTable employees={employees} />
    </Flex>
  );
};

export default StatisticsPanel;
