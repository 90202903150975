import React from "react";

import { useSelector } from "react-redux";

import Header from "../components/molecules/Header";
import TabBar from "../components/molecules/TabBar";
import { Layout, Content } from "./layout.styled";

const AppLayout = ({ theme, children, isprimary }) => {
  const { loggedIn } = useSelector(state => state?.authReducer);

  return (
    <Layout>
      <Header theme={theme} isprimary={isprimary} />
      <Content isprimary={isprimary}>{children}</Content>
      {loggedIn && <TabBar />}
    </Layout>
  );
};

export default AppLayout;
