import React from "react";

import { UserOutlined } from "@ant-design/icons";
import { Formik, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { baseColor } from "../../../settings/foundations";
import { forgotPasswordAction } from "../../../stores/actions/forgotPassword.actions";
import { Button } from "../../atoms/Button";
import { Box } from "../../atoms/Flexbox";
import { Input } from "../../atoms/Input";
import { Text } from "../../atoms/Typography";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address!")
    .required("Email is a required field!"),
});

const Form = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    state => state?.forgotPasswordAction?.isLoading
  );

  return (
    <Formik
      validationSchema={ForgotPasswordSchema}
      initialValues={{ email: "" }}
      onSubmit={values => {
        dispatch(forgotPasswordAction(values.email));
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Box display="flex" flexDirection="column" marginBottom={16}>
            <Input
              size="large"
              name="email"
              type="text"
              placeholder="Email"
              onBlur={props.handleBlur}
              onChange={e => {
                const value = e.target.value || "";
                props.setFieldValue("email", value.toLowerCase());
              }}
              value={props.values.email}
              prefix={<UserOutlined style={{ color: baseColor.primary }} />}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="email" />
            </Text>
          </Box>

          <Button
            block
            type="primary"
            fontSize={16}
            size="large"
            htmlType="submit"
            loading={isLoading}
          >
            Reset
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default Form;
