/* eslint-disable react/destructuring-assignment */
import React from "react";

import { Table } from "antd";

import { baseColor } from "../../../settings/foundations";
import { Flex, Box } from "../../atoms/Flexbox";
import { Title } from "../../atoms/Typography";
import { useHelper } from "./useHelper";

const StatsTable = ({ employees }) => {
  const helper = useHelper();

  return (
    <Flex flexDirection="column" px={[12, 10, 0]}>
      <Box width={1} mt={50}>
        <Title fontSize={24} color={baseColor.black}>
          Breakdown by employees
        </Title>
      </Box>
      <Box width={1} mt={14} mb={50}>
        <Table
          rowKey="id"
          columns={helper.columns}
          dataSource={employees}
          pagination={false}
        />
      </Box>
    </Flex>
  );
};

export default StatsTable;
