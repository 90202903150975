import styled from "@emotion/styled";
import { Layout, Menu as AntMenu, Drawer as AntDrawer } from "antd";

import { baseColor } from "../../../settings/foundations";

const headerTheme = {
  absolute: {
    position: "absolute",
    zindex: "100",
    background: "transparent",
  },
  primary: {
    position: "fixed",
    zindex: "100",
    background: baseColor.white,
    boxShadow: "0px 0px 8px 0px #0000001A",
  },
};

export const Header = styled(Layout.Header)`
  width: 100%;
  line-height: 0;
  padding: 16px 40px;
  display: flex;
  align-items: center;
  position: ${props =>
    props.theme ? headerTheme[props.theme]?.position : "default"};
  z-index: ${props => (props.theme ? headerTheme[props.theme]?.zindex : "")};
  background: ${props =>
    props.theme ? headerTheme[props.theme]?.background : ""};
  box-shadow: ${props =>
    props.theme ? headerTheme[props.theme]?.boxShadow : "none"};

  @media (max-width: 1023px) {
    padding: 16px 10px;
  }
`;

export const MobileView = styled.div`
  display: none;
  padding: 0;
  margin: 0;

  @media (max-width: 1200px) {
    display: block;
  }
`;

export const Hamberger = styled.button`
  height: 100%;
  outline: none;
  border: none;
  background-color: white;
  margin-left: 15px;
  &:hover {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

export const MenuList = styled.ul`
  display: ${props => (props.mobile ? "none" : "flex")};
  list-style: none;
  padding: 0;
  margin: 0;

  @media (max-width: 1200px) {
    display: ${props => (props.mobile ? "block" : "none")};
    list-style: none;
    li {
      margin: 0 0 20px 0px;
      a {
        display: block;
      }
    }
  }
`;

export const MenuItem = styled.li`
  background-color: ${baseColor.white};
  outline: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin: 0 12px;
  span {
    margin-left: 5px;
  }
  a {
    padding: 9px;
    border-radius: 3px;
    text-decoration: none;
    color: ${baseColor.black};
  }
  &:hover {
    a {
      background-color: ${baseColor.lightBlue};
      color: ${baseColor.darkBlue};
    }
  }
  &.active {
    a {
      background-color: ${baseColor.lightBlue};
      color: ${baseColor.darkBlue};
    }
  }
`;

export const LogoWrapper = styled.div`
  min-width: 6rem;
`;

export const Menu = styled(AntMenu)``;

export const Drawer = styled(AntDrawer)``;
