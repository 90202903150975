import styled from "@emotion/styled";
import { Affix as AntAffix } from "antd";

import { baseColor } from "../../../settings/foundations";

export const Affix = styled(AntAffix)`
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -1px 4px rgba(128, 128, 128, 0.18);
`;

export const MenuItem = styled.div`
  a {
    border-radius: 3px;
    text-decoration: none;
    color: ${baseColor.black};

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      text-align: center;
    }

    &.active {
      color: ${baseColor.darkBlue};
    }
  }
`;
