import styled from "@emotion/styled";
import { Flex as RebassFlex, Box as RebassBox } from "rebass";

export const Box = styled(RebassBox)`
  position: ${props => (props.position ? props.position : "")};
  bottom: ${props => (props.bottom ? props.bottom : "")};
  top: ${props => (props.top ? props.top : "")};
  left: ${props => (props.left ? props.left : "")};
  right: ${props => (props.right ? props.right : "")};
  border: ${props => (props.border ? props.border : "")};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "")};
  border-right: ${props => (props.borderRight ? props.borderRight : "")};
  border-left: ${props => (props.borderLeft ? props.borderLeft : "")};
  border-top: ${props => (props.borderTop ? props.borderTop : "")};
  border-bottom: ${props => (props.borderBottom ? props.borderBottom : "")};
`;

export const Flex = styled(RebassFlex)`
  position: ${props => (props.position ? props.position : "")};
  bottom: ${props => (props.bottom ? props.bottom : "")};
  top: ${props => (props.top ? props.top : "")};
  left: ${props => (props.left ? props.left : "")};
  right: ${props => (props.right ? props.right : "")};
  border: ${props => (props.border ? props.border : "")};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "")};
  border-right: ${props => (props.borderRight ? props.borderRight : "")};
  border-left: ${props => (props.borderLeft ? props.borderLeft : "")};
  border-top: ${props => (props.borderTop ? props.borderTop : "")};
  border-bottom: ${props => (props.borderBottom ? props.borderBottom : "")};
`;
