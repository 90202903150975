import React from "react";

import { StyledList, StyledItem } from "./scroll.styles";

const SideScroll = ({ menu, handleScroll }) => {
  return (
    <StyledList>
      {menu.map(item => (
        <li>
          <StyledItem
            spy
            smooth
            duration={700}
            offset={-280}
            to={item.path}
            onClick={() => handleScroll(item.path)}
            activeClass="active"
          >
            {item.title}
          </StyledItem>
        </li>
      ))}
    </StyledList>
  );
};

export default SideScroll;
