import moment from "moment";

export const addTimes = times => {
  let duration = 0;
  times?.forEach(time => {
    duration += moment.duration(time.timeLogged).as("milliseconds");
  });

  const d = moment.duration(duration, "milliseconds");
  const hours = Math.floor(d.asHours());
  const mins = Math.floor(d.asMinutes()) - hours * 60;
  const time = `${
    hours > 0
      ? `${hours >= 10 ? hours.toString() : `0${hours.toString()}`}`
      : `00`
  }${
    mins > 0
      ? `:${mins >= 10 && mins <= 60 ? mins.toString() : `0${mins.toString()}`}`
      : `:00`
  }`;
  return time;
};
