import { rolesConstants } from "../../constants";

export default function rolesReducer(state = { roles: [] }, action) {
  switch (action.type) {
    case rolesConstants.GET_ROLES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case rolesConstants.GET_ROLES_SUCCESS:
      return {
        isLoading: false,
        roles: action.payload,
      };
    case rolesConstants.GET_ROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
