import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import Statistics from "../components/organisms/Statistics";
import AppLayout from "../Layout";
import { checkAuth } from "../stores/actions/auth.actions";

const StatisticsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  return (
    <AppLayout theme="primary" isprimary>
      <Statistics />
    </AppLayout>
  );
};

export default StatisticsPage;
