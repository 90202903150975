import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import Settings from "../components/organisms/Settings";
import AppLayout from "../Layout";
import { checkAuth } from "../stores/actions/auth.actions";

const SettingsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  return (
    <AppLayout theme="primary" isprimary>
      <Settings />
    </AppLayout>
  );
};

export default SettingsPage;
