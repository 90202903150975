import styled from "@emotion/styled";
import { Typography as AntTypography } from "antd";

import { baseColor } from "../../../settings/foundations";

export const Title = styled(AntTypography.Title)`
  font-family: "Roboto";
  font-size: ${props =>
    props.fontSize ? `${props.fontSize}px !important` : ""};
  font-weight: ${props =>
    props.fontWeight ? `${props.fontWeight} !important` : ""};
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px !important` : ""};
  color: ${props =>
    props.color ? `${props.color} !important` : baseColor.black};
  padding: ${props => (props.padding ? `${props.padding}px !important` : "")};
  padding-bottom: ${props =>
    props.paddingbottom ? `${props.paddingbottom}px !important` : ""};
  padding-top: ${props =>
    props.paddingtop ? `${props.paddingtop}px !important` : ""};
  padding-left: ${props =>
    props.paddingleft ? `${props.paddingleft}px !important` : ""};
  padding-right: ${props =>
    props.paddingright ? `${props.paddingright}px !important` : ""};
  margin: ${props => (props.margin ? `${props.margin}px !important` : "")};
  margin-bottom: ${props =>
    props.marginbottom ? `${props.marginbottom}px !important` : ""};
  margin-top: ${props =>
    props.margintop ? `${props.margintop}px !important` : ""};
  margin-left: ${props =>
    props.marginleft ? `${props.marginleft}px !important` : ""};
  margin-right: ${props =>
    props.marginright ? `${props.marginright}px !important` : ""};
  height: ${props => (props.Height ? `${props.Height}` : "")};
`;

export const Text = styled(AntTypography.Text)`
  font-family: "Roboto";
  font-size: ${props =>
    props.fontSize ? `${props.fontSize}px !important` : ""};
  font-weight: ${props =>
    props.fontWeight ? `${props.fontWeight} !important` : ""};
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px !important` : ""};
  color: ${props =>
    props.color ? `${props.color} !important` : baseColor.black};
  padding: ${props => (props.padding ? `${props.padding}px !important` : "")};
  padding-bottom: ${props =>
    props.paddingbottom ? `${props.paddingbottom}px !important` : ""};
  padding-top: ${props =>
    props.paddingtop ? `${props.paddingtop}px !important` : ""};
  padding-left: ${props =>
    props.paddingleft ? `${props.paddingleft}px !important` : ""};
  padding-right: ${props =>
    props.paddingright ? `${props.paddingright}px !important` : ""};
  margin: ${props => (props.margin ? `${props.margin}px !important` : "")};
  margin-bottom: ${props =>
    props.marginbottom ? `${props.marginbottom}px !important` : ""};
  margin-top: ${props =>
    props.margintop ? `${props.margintop}px !important` : ""};
  margin-left: ${props =>
    props.marginleft ? `${props.marginleft}px !important` : ""};
  margin-right: ${props =>
    props.marginright ? `${props.marginright}px !important` : ""};
  height: ${props => (props.Height ? `${props.Height}` : "")};
  text-transform: ${props => (props.texttransform ? props.texttransform : "")};
  cursor: ${props => (props.cursor ? props.cursor : "")};
`;

export const Paragraph = styled(AntTypography.Paragraph)`
  word-wrap: break-word;
  font-family: "Roboto";
  font-size: ${props =>
    props.fontSize ? `${props.fontSize}px !important` : ""};
  font-weight: ${props =>
    props.fontWeight ? `${props.fontWeight} !important` : ""};
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px !important` : ""};
  color: ${props =>
    props.color ? `${props.color} !important` : baseColor.black};
  padding: ${props => (props.padding ? `${props.padding}px !important` : "")};
  padding-bottom: ${props =>
    props.paddingbottom ? `${props.paddingbottom}px !important` : ""};
  padding-top: ${props =>
    props.paddingtop ? `${props.paddingtop}px !important` : ""};
  padding-left: ${props =>
    props.paddingleft ? `${props.paddingleft}px !important` : ""};
  padding-right: ${props =>
    props.paddingright ? `${props.paddingright}px !important` : ""};
  margin: ${props => (props.margin ? `${props.margin}px !important` : "")};
  margin-bottom: ${props =>
    props.marginbottom ? `${props.marginbottom}px !important` : ""};
  margin-top: ${props =>
    props.margintop ? `${props.margintop}px !important` : ""};
  margin-left: ${props =>
    props.marginleft ? `${props.marginleft}px !important` : ""};
  margin-right: ${props =>
    props.marginright ? `${props.marginright}px !important` : ""};
  height: ${props => (props.Height ? `${props.Height}` : "")};
  text-transform: ${props => (props.texttransform ? props.texttransform : "")};
  cursor: ${props => (props.cursor ? props.cursor : "")};
`;
