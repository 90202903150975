import React, { useState } from "react";

import { PlusCircleFilled } from "@ant-design/icons";
import { chain, filter } from "lodash";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

import { useScroll } from "../../../helpers/useScroll";
import { addTimes } from "../../../helpers/util";
import { baseColor } from "../../../settings/foundations";
import { Avatar } from "../../atoms/Avatar";
import { Button } from "../../atoms/Button";
import { Flex, Box } from "../../atoms/Flexbox";
import { Title, Text } from "../../atoms/Typography";
import AddTimelogUpsert from "./addTimelogUpsert";
import TimeSheetTable from "./table";
import { StyledActionButtons, StyledTotalHours } from "./timeSheets.styles";

const TimeSheet = ({ employee, isAdmin }) => {
  const [isOpen, setOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { scrollPosition } = useScroll();

  const computedTimeLog = chain(employee?.loggedTimes)
    .groupBy(loggedTime => moment(loggedTime.date).isoWeek())
    .map((timeLogs, week) => ({ week, timeLogs }))
    .value();

  const approvedTimeLogs = filter(employee?.loggedTimes, timeLog => {
    return timeLog?.status === true;
  });

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    localStorage.setItem("scrollPosition", scrollPosition);
  };

  return (
    <>
      <Flex
        width={1}
        my={40}
        p={[12, 40]}
        mx="auto"
        flexDirection="column"
        border="1px solid #DFE0EB"
        borderRadius="8px"
      >
        <Flex
          width={1}
          pb={23}
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid #DFE0EB"
        >
          <Flex alignItems="center">
            <Box mr={16}>
              <Avatar
                size={isMobile ? 38 : 64}
                bg={baseColor.lightRed}
                color={baseColor.red}
              >
                {employee?.firstname.charAt(0).toUpperCase() +
                  employee?.lastname.charAt(0).toUpperCase()}
              </Avatar>
            </Box>
            <Box>
              <Title
                fontSize={isMobile ? 16 : 24}
                color={baseColor.gray}
                style={{ marginBottom: "0px" }}
              >
                {`${employee?.firstname} ${employee?.lastname}`}
              </Title>
            </Box>
          </Flex>
          <Flex
            px={40}
            textAlign={["center", "center", "left"]}
            flexDirection="column"
            borderLeft="1px solid #DFE0EB"
          >
            <Text fontSize={14} color={baseColor.darkGray}>
              Total hours
            </Text>
            <Flex
              mt={10}
              alignItems="center"
              flexDirection={["column", "column", "row"]}
            >
              <Flex style={{ gap: "3px" }}>
                <StyledTotalHours>
                  <Title
                    fontSize={isMobile ? 16 : 24}
                    color={baseColor.gray}
                    style={{ marginBottom: "0px" }}
                  >
                    <span style={{ color: baseColor.green }}>
                      {`${addTimes(approvedTimeLogs)} `}
                    </span>
                    /
                  </Title>
                </StyledTotalHours>
                <Title
                  fontSize={isMobile ? 16 : 24}
                  color={baseColor.gray}
                  style={{ marginBottom: "0px", marginTop: "0px" }}
                >
                  {` ${addTimes(employee?.loggedTimes)}`}
                </Title>
              </Flex>

              {isAdmin && (
                <Box ml={16} mt={[10, 0]}>
                  <StyledActionButtons>
                    <Button
                      paddingtop={isMobile ? 9 : 0}
                      paddingbottom={isMobile ? 9 : 0}
                      type="primary"
                      fontSize={isMobile ? 12 : 14}
                      backgroundColor={baseColor.primary}
                      borderColor={baseColor.primary}
                      icon={<PlusCircleFilled />}
                      onClick={() => handleOpen()}
                    >
                      Add Time
                    </Button>
                  </StyledActionButtons>
                </Box>
              )}
            </Flex>
          </Flex>
        </Flex>

        {computedTimeLog.map((weekTimeLogs, index) => {
          return (
            <TimeSheetTable
              weekTimeLogs={weekTimeLogs}
              isAdmin={isAdmin}
              shwoAddTimeLog={index === 0}
              employee={employee}
            />
          );
        })}
      </Flex>

      <AddTimelogUpsert
        isOpen={isOpen}
        handleCancel={handleCancel}
        userId={employee.id}
      />
    </>
  );
};

export default TimeSheet;
