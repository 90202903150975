import React from "react";

import { LockOutlined } from "@ant-design/icons";
import { Formik, ErrorMessage } from "formik";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";

import { baseColor } from "../../../settings/foundations";
import { setPassword } from "../../../stores/actions/auth.actions";
import { Button } from "../../atoms/Button";
import { Box } from "../../atoms/Flexbox";
import { Password } from "../../atoms/Input";
import { Text } from "../../atoms/Typography";

const SetPasswordSchema = Yup.object().shape({
  password: Yup.string().required("Password is a required field!"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Passwords must match"),
});

const Form = () => {
  const dispatch = useDispatch();
  const loggingIn = useSelector(state => state?.authReducer?.loggingIn);

  const parsed = queryString.parse(window.location.search);

  return (
    <Formik
      validationSchema={SetPasswordSchema}
      initialValues={{ password: "", passwordConfirmation: "" }}
      onSubmit={values => {
        dispatch(setPassword(parsed.authToken, values.password));
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Box display="flex" flexDirection="column" marginBottom={16}>
            <Password
              size="large"
              name="password"
              type="password"
              placeholder="Password"
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              value={props.values.password}
              prefix={<LockOutlined style={{ color: baseColor.primary }} />}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="password" />
            </Text>
          </Box>

          <Box display="flex" flexDirection="column" marginBottom={24}>
            <Password
              size="large"
              name="passwordConfirmation"
              type="password"
              placeholder="Confirm Password"
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              value={props.values.passwordConfirmation}
              prefix={<LockOutlined style={{ color: baseColor.primary }} />}
            />
            <Text color={baseColor.red}>
              <ErrorMessage name="passwordConfirmation" />
            </Text>
          </Box>

          <Button
            size="large"
            block
            type="primary"
            fontSize={16}
            htmlType="submit"
            loading={loggingIn}
          >
            Submit
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default Form;
