import { message } from "antd";

import { userConstants } from "../../constants";
import http from "../../services/httpService";

export function login(email, password) {
  return dispatch => {
    dispatch({ type: userConstants.LOGIN_REQUEST });
    http
      .post("/users/login", {
        email,
        password,
      })
      .then(res => {
        if (res?.data?.user?.status) {
          message.success("Welcome!");
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("token", res.data.token);
          dispatch({
            type: userConstants.LOGIN_SUCCESS,
            payload: {
              token: res.data.token,
              user: res.data.user,
            },
          });
          window.location.replace("/statistics");
        } else {
          message.error(
            "Unable to login. please contact with your system admin."
          );
        }
      })
      .catch(() => {
        message.error("Invalid credentials, unable to login.");
        dispatch({ type: userConstants.LOGIN_FAILURE });
      });
  };
}

export function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  window.location.replace("/");
  return { type: userConstants.LOGOUT };
}

export function setPassword(authToken, password) {
  return dispatch => {
    dispatch({ type: userConstants.LOGIN_REQUEST });
    http
      .patch(`/users/setPassword?authToken=${authToken}`, {
        password,
      })
      .then(res => {
        message.success("Welcome!");
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("token", res.data.token);
        dispatch({
          type: userConstants.LOGIN_SUCCESS,
          payload: {
            token: res.data.token,
            user: res.data.user,
          },
        });
        if (
          res?.data?.user?.role === "SUPER ADMIN" ||
          res.data.user.role === "ADMIN" ||
          res.data.user.role === "MANAGER"
        ) {
          window.location.replace("/statistics");
        } else {
          window.location.replace("/time-sheets");
        }
      })
      .catch(() => {
        message.error("Something went worng. Unable to set password");
        dispatch({ type: userConstants.LOGIN_FAILURE });
      });
  };
}

export function checkAuth() {
  return dispatch => {
    http
      .get("/auth")
      .then(res => {
        if (res?.data?.user?.status) {
          localStorage.setItem("user", JSON.stringify(res.data.user));
          dispatch({
            type: userConstants.SET_USERS_DETAILS,
            payload: res.data.user,
          });
        } else {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.replace("/");
        }
      })
      .catch(() => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.replace("/");
        return { type: userConstants.LOGOUT };
      });
  };
}
