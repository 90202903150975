import React from "react";

import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { useScroll } from "../../../helpers/useScroll";
import { updateTimesheetAction } from "../../../stores/actions/timeSheet.action";
import Form from "./form";

const Upsert = ({ isOpen, handleCancel }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
  const { timeSheet } = useSelector(state => state?.timeSheetReducer);
  const dispatch = useDispatch();
  const { scrollPosition } = useScroll();

  const onSubmit = values => {
    dispatch(
      updateTimesheetAction(values.id, {
        ProjectCategoryId: values.ProjectCategoryId,
        ProjectId: values.ProjectId,
        UserId: values.UserId,
        date: values.date,
        description: values.description,
        endTime: values.endTime,
        startTime: values.startTime,
        breakTime: values.breakTime,
      })
    );
    localStorage.setItem("scrollPosition", scrollPosition);
  };

  return (
    <Modal
      title="Edit Time"
      centered={!isTabletOrMobile}
      destroyOnClose
      visible={isOpen}
      footer={null}
      onCancel={handleCancel}
      style={{
        top: 0,
        overflowY: "auto",
        minHeight: isTabletOrMobile ? "100vh" : "auto ",
      }}
      bodyStyle={{
        top: 0,
        left: 0,
        overflowY: "auto",
      }}
    >
      <Form
        handleCancel={handleCancel}
        onSubmit={onSubmit}
        initialValues={timeSheet}
      />
    </Modal>
  );
};

export default Upsert;
