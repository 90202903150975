import styled from "@emotion/styled";
import { Select as AntSelect } from "antd";

import { baseColor } from "../../../settings/foundations";

export const { Option } = AntSelect;

export const Select = styled(AntSelect)`
  font-family: "Roboto";
  width: ${props => (props.width ? props.width : "100%")};
  font-size: ${props =>
    props.fontSize ? `${props.fontSize}px !important` : ""};
  font-weight: ${props =>
    props.fontWeight ? `${props.fontWeight} !important` : "400"};
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px !important` : ""};
  color: ${props =>
    props.color ? `${props.color} !important` : baseColor.lightBlack};
  padding: ${props => (props.padding ? `${props.padding}px !important` : "")};
  padding-bottom: ${props =>
    props.paddingbottom ? `${props.paddingbottom}px !important` : ""};
  padding-top: ${props =>
    props.paddingtop ? `${props.paddingtop}px !important` : ""};
  padding-left: ${props =>
    props.paddingleft ? `${props.paddingleft}px !important` : ""};
  padding-right: ${props =>
    props.paddingright ? `${props.paddingright}px !important` : ""};
  margin: ${props => (props.margin ? `${props.margin}px !important` : "")};
  margin-bottom: ${props =>
    props.marginbottom ? `${props.marginbottom}px !important` : ""};
  margin-top: ${props =>
    props.margintop ? `${props.margintop}px !important` : ""};
  margin-left: ${props =>
    props.marginleft ? `${props.marginleft}px !important` : ""};
  margin-right: ${props =>
    props.marginright ? `${props.marginright}px !important` : ""};

  border-color: ${props =>
    props.bordercolor ? props.bordercolor : baseColor.midGray};

  .ant-select-selection-placeholder {
    color: ${props =>
      props.placehodercolor ? props.placehodercolor : baseColor.lightBlack};
  }

  .ant-select-selection-search {
    border-right: ${props =>
      props.split ? `1px solid ${baseColor.midGray}` : ""};
    right: ${props => (props.split ? "32px !important" : "")};
  }
`;
