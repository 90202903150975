import { useState, useEffect } from "react";

export const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    const storedPosition = localStorage.getItem("scrollPosition");
    if (storedPosition) {
      window.scrollTo(0, storedPosition);
      setScrollPosition(storedPosition);
    }

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return {
    scrollPosition,
  };
};
