import { commentsConstants } from "../../constants";

const commentFormValues = {
  comment: "",
};

export default function commentsReducer(
  state = {
    comments: null,
    commentFormValues,
  },
  action
) {
  switch (action.type) {
    case commentsConstants.ADD_COMMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        added: false,
      };
    case commentsConstants.ADD_COMMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        added: true,
      };
    case commentsConstants.ADD_COMMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        added: true,
      };
    case commentsConstants.SET_TIME_ID:
      return {
        ...state,
        commentFormValues: action.payload,
      };
    case commentsConstants.SET_COMMENT_DATA:
      return {
        ...state,
        commentFormValues: action.payload,
        isLoading: false,
      };
    case commentsConstants.RESET_COMMENT_DATA:
      return {
        ...state,
        commentFormValues,
        isLoading: false,
      };
    case commentsConstants.UPDATE_COMMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        added: false,
      };
    case commentsConstants.UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        added: true,
      };
    case commentsConstants.UPDATE_COMMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        added: true,
      };
    default:
      return state;
  }
}
