import React from "react";

import { useMediaQuery } from "react-responsive";

import { ReactComponent as LoginIcon } from "../../../assets/icons/login.svg";
import { baseColor } from "../../../settings/foundations";
import { Flex, Box } from "../../atoms/Flexbox";
import { Link } from "../../atoms/Link";
import { Title, Text } from "../../atoms/Typography";
import LanguageSwitch from "../../molecules/Language";
import Form from "./form";

const LoginPanel = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });

  return (
    <Flex bg={baseColor.white} maxWidth={["100%", "1920px"]}>
      <Flex
        width={1}
        minHeight={["auto", "100vh"]}
        flexDirection={["column-reverse", "row"]}
      >
        <Box
          width={["100%", 1 / 2]}
          bg={baseColor.lightGray}
          position="relative"
          display="inline-flex"
          flexDirection="column"
          px={[10, 20, 0]}
          pt={[40, 0]}
          pb={[150, 0]}
        >
          <Box
            width={1}
            textAlign="center"
            maxWidth={["100%", 370]}
            margin="auto"
          >
            <Title
              fontSize={38}
              color={baseColor.gray}
              marginbottom={isTabletOrMobile ? 10 : 16}
            >
              Welcome!
            </Title>
            <Text fontSize={16} color={baseColor.gray} marginbottom={40}>
              Use Umax Dashboard to manage working hours
            </Text>
            <Box textAlign="left" marginTop={[24, 40]} marginBottom={16}>
              <Form />
            </Box>
            <Link to="/forgot-password" fontSize={16}>
              Forgot your password?
            </Link>
          </Box>

          <Box
            position={isTabletOrMobile ? "static" : "absolute"}
            bottom="32px"
            textAlign="center"
            width={1}
            mt={[4, 0]}
          >
            <LanguageSwitch />
          </Box>
        </Box>
        <Box
          width={[1, 1 / 2]}
          display="inline-flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box textAlign="center" mt={[4, 0]} mb={[2, 0]}>
            <LoginIcon
              style={{
                width: isTabletOrMobile ? "220px" : "416px",
                height: isTabletOrMobile ? "220px" : "390px",
              }}
            />
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default LoginPanel;
